import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from 'app/main/impostazioni/users/model/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  //Inizializzato vuoto perchè ToolbarComponent viene inizializzato prima del Login
  user: User = new User();

  routeParams: any;
  onUserChanged: BehaviorSubject<any>;

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions,
    public afAuth: AngularFireAuth
  ) {
    this.onUserChanged = new BehaviorSubject({});
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getUserById(this.routeParams.id)]).then(() => {
        resolve();
      }, reject);
    });
  }

  getUserById(id: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this.db.doc<User>('users/' + id).valueChanges()
        .pipe(take(1))
        .subscribe({
          next: (user) => {
            if (user) {
              this.user = user;
              this.user.id = id;
            }

            this.onUserChanged.next(this.user);
            resolve(user);
          },
          error: reject
        });
    });
  }

  getUsers() {
    return this.db.collection('users').valueChanges({ idField: 'id' });
  }

  updateUser(user: any) {
    const id = user.id;
    delete user.id;

    return this.db.doc('users/' + id).update(user);
  }

  deleteUser(id: string) {
    return this.fns.httpsCallable('deleteUser')({ uid: id });
  }

  disattivaUser(id: string) {
    return this.db.collection('users').doc(id).update({ 'claims.attivo': false });
  }

  attivaUser(id: string) {
    return this.db.collection('users').doc(id).update({ 'claims.attivo': true });
  }

}
