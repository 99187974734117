import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class UtilService {

  constructor(
    private db: AngularFirestore
  ) { }

  get nazioni() {
    return this.db.firestore.collection('nazioni').orderBy('id').get();
  }

}
