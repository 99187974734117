import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CalendarEvent, CalendarEventAction, CalendarMonthViewDay } from 'angular-calendar';
import { AuthService } from 'app/authentication/auth.service';
import { UsersService } from 'app/main/impostazioni/users/users.service';
import { SnackbarService } from 'app/shared/service/snackbar.service';
import { isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgendaService } from './agenda.service';
import { AgendaEventFormDialogComponent } from './event-form/event-form.component';
import { CalendarEventModel } from './model/event.model';

@Component({
  selector: 'agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class AgendaComponent implements OnInit {
  @Input() parent;

  locale: string = "it";
  excludeDays: number[] = [];

  actions: CalendarEventAction[];
  activeDayIsOpen: boolean;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  dialogRef: any;
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  selectedDay: any;
  view: string;
  viewDate: Date;

  test: CalendarEventModel;

  user = new UntypedFormControl();
  users: any[];
  userSelezionato: any;

  fromProgetto: boolean = false;
  progetto: string;
  fase: string;
  loggedUser: any;

  checked = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _matDialog: MatDialog,
    private agendaService: AgendaService,
    private snackbarService: SnackbarService,
    private usersService: UsersService,
    public authService: AuthService,
    private router: Router
  ) {
    this._unsubscribeAll = new Subject();
    // Set the defaults
    this.view = 'week';
    this.viewDate = new Date();
    this.activeDayIsOpen = true;
    this.selectedDay = { date: startOfDay(new Date()) };

    this.loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    this.userSelezionato = this.loggedUser;
    console.log('this.userSelezionato', this.userSelezionato);

    this.actions = [
      {
        label: '<i class="material-icons s-16">edit</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.editEvent('edit', event);
        }
      },
      {
        label: '<i class="material-icons s-16">delete</i>',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.deleteEvent(event);
        }
      }
    ];

    const navigation = this.router.getCurrentNavigation();

    if (navigation.extras.state) {
      console.log(navigation.extras.state);

      const data = navigation.extras.state as {
        progetto: string,
        fase: string
      };

      this.fromProgetto = true;
      this.progetto = data.progetto;
      this.fase = data.fase;
    }

    /**
     * Get events from service/server
     */
    this.setEvents();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    /**
     * Watch re-render-refresh for updating db
     */
    /*this.refresh.subscribe(updateDB => {
        if (updateDB) {
            this.agendaService.updateEvents(this.events);
        }
    });*/

    if (this.fromProgetto) {
      this.addEvent(this.progetto, this.fase);
    }

    this.agendaService.onEventsUpdated.subscribe(events => {
      this.setEvents();
      this.refresh.next(false);
    });

    this.usersService.getUsers()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(users => {
        this.users = users;
      });


    this.user.valueChanges.subscribe((user) => {
      this.agendaService.getEventsByUser(user.id);
      this.userSelezionato = user;
      this.checked = false;
    });

    if (this.parent == 'todo') this.excludeDays = [0, 6];
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set events
   */
  setEvents(): void {

    this.events = this.agendaService.events.map(item => {
      item.actions = this.actions;
      //item.title = item.progetto.nome + " - " + item.fase.descrizione;
      return new CalendarEventModel(item);
    });

    /*this.events = [];

    this.agendaService.events.map(item => {

        for (const [key, value] of Object.entries(item.scadenze)) {
            const c: any = value;

            let test = new CalendarEventModel;

            test.id = item.id;
            test.start = new Date(c.seconds * 1000);
            test.end = new Date(c.seconds * 1000);

            switch (item.tipo) {
                case 'marchio':
                    test.color = { primary: '#E23B18', secondary: '#E28B79' };
                    break;
                case 'design':
                    test.color = { primary: '#7CFC00', secondary: '#98FB98' };
                    break;
                case 'brevetto':
                    test.color = { primary: '#1e90ff', secondary: '#D1E8FF' };
                    break;
            }

            let scadenza = key.charAt(0).toUpperCase() + key.slice(1);

            test.title = item.titolo + " - " + scadenza.replace(/([A-Z])/g, ' $1').trim();
            test.allDay = true;

            test.meta.notes = item.tipo;

            this.events.push(test);
        }

        //this.test.actions = this.actions;
    });*/
  }

  /**
   * Before View Renderer
   *
   * @param {any} header
   * @param {any} body
   */
  beforeMonthViewRender({ header, body }): void {
    /**
     * Get the selected day
     */
    const _selectedDay = body.find((_day) => {
      return _day.date.getTime() === this.selectedDay.date.getTime();
    });

    if (_selectedDay) {
      /**
       * Set selected day style
       * @type {string}
       */
      _selectedDay.cssClass = 'cal-selected';
    }

  }

  /**
   * Day clicked
   *
   * @param {MonthViewDay} day
   */
  dayClicked(day: CalendarMonthViewDay): void {
    const date: Date = day.date;
    const events: CalendarEvent[] = day.events;

    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      }
      else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
    this.selectedDay = day;
    this.refresh.next(false);
  }

  /**
   * Delete Event
   *
   * @param event
   */
  deleteEvent(event): void {
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Sei sicuro di voler cancellare l\'evento?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.agendaService.deleteEvent(event)
          .then(() => this.snackbarService.open("Eliminazione effettuata correttamente", "top", 5000, "snackbar-style-succes"))
          .catch((e) => this.snackbarService.open("Errore durante l'eliminazione " + e, "top", 5000, "snackbar-style-error"));
      }
    });

  }

  /**
   * Edit Event
   *
   * @param {string} action
   * @param {CalendarEvent} event
   */
  editEvent(action: string, event: CalendarEvent): void {
    /*switch (event.meta.notes) {
        case 'design':
            this.router.navigate(['design/' + event.id]);
            break;
        case 'marchio':
            this.router.navigate(['marchi/' + event.id]);
            break;
        default:
            this.router.navigate(['brevetti/' + event.id]);
            break;
    }*/

    this.dialogRef = this._matDialog.open(AgendaEventFormDialogComponent, {
      panelClass: 'event-form-dialog',
      data: {
        event: event,
        action: action
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: UntypedFormGroup = response[1];
        switch (actionType) {
          case 'save':
            this.agendaService.updateEvents(formData.getRawValue())
              .then(() => this.snackbarService.open("Update effettuato correttamente", "top", 5000, "snackbar-style-succes"))
              .catch((e) => this.snackbarService.open("Errore durante l'update " + e, "top", 5000, "snackbar-style-error"));
            break;
          case 'delete':
            this.deleteEvent(event);
            break;
        }
      });
  }

  /**
   * Add Event
   */
  addEvent(progetto?: string, fase?: string): void {
    let utenteCalendario
    if (this.userSelezionato) utenteCalendario = this.userSelezionato;
    else utenteCalendario = this.loggedUser;

    this.dialogRef = this._matDialog.open(AgendaEventFormDialogComponent, {
      panelClass: 'event-form-dialog',
      data: {
        event: new CalendarEventModel(),
        action: 'new',
        date: this.selectedDay.date,
        progetto: progetto,
        fase: fase,
        user: utenteCalendario
      }
    });
    this.dialogRef.afterClosed()
      .subscribe((response: UntypedFormGroup) => {
        if (!response) {
          return;
        }

        console.log('entra da addEvent')
        console.log(response.getRawValue());

        const newEvent = response.getRawValue();

        this.agendaService.addEvents(newEvent)
          .then(() => this.snackbarService.open("Evento aggiunto correttamente", "top", 5000, "snackbar-style-succes"))
          .catch((e) => this.snackbarService.open("Errore durante la creazione " + e, "top", 5000, "snackbar-style-error"));
      });
  }

  updateTimesheet() {
    this.agendaService.updateTimesheet();
  }

  lockTimesheet() {
    this.agendaService.lockTimesheet();
  }

  vediScadenzario() {
    let utenteCalendario
    if (this.userSelezionato) utenteCalendario = this.userSelezionato;
    else utenteCalendario = this.loggedUser;

    console.log('utenteCalendario', utenteCalendario);
    if (!this.checked)
      this.agendaService.vediScadenzario();
    else
      this.agendaService.getEventsByUser(utenteCalendario.id);
  }

}


