import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UsersService } from 'app/main/impostazioni/users/users.service';
import { ProgettoService } from 'app/main/progetti/progetto/progetto.service';
import { HOURS, MINUTES } from 'app/shared/app.constants';
import { InformazioniService } from 'app/shared/service/informazioni.service';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';

@Component({
  selector: 'calendar-event-form-dialog',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AgendaEventFormDialogComponent {
  action: string;
  event: any;
  eventForm: UntypedFormGroup;
  dialogTitle: string;

  //user = new UntypedFormControl();
  users: any[];
  usersFiltered: Observable<any[]>;

  progetti: any[];
  progettiFiltered: Observable<any[]>;

  fasi: any[] = [];
  fasiFiltered: Observable<any[]>;

  hours: string[] = HOURS;
  minutes: string[] = MINUTES;

  naturaSalva: string;
  tipiNaturaLista: any = [];
  sottotipologie: any = [];
  listeSottotipologie: any = [];
  // Filtri
  naturaFilter: UntypedFormControl = new UntypedFormControl();
  sottotipologiaFilter: UntypedFormControl = new UntypedFormControl();

  oggettiSB: any[];
  oggettiSBFiltered: Observable<any[]>;
  descOggettoSB: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {MatDialogRef<AgendaEventFormDialogComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<AgendaEventFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private progettoService: ProgettoService,
    private infoService: InformazioniService,
    private usersService: UsersService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(italiano, english);
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.event = _data.event;
    this.action = _data.action;

    if (this.action === 'edit') {
      this.dialogTitle = "Modifica evento";
    }
    else {
      this.dialogTitle = 'Nuovo evento';
      this.event.start = _data.date;
      this.event.end = _data.date;
      this.event.stato = true;

      if (_data.progetto) this.event.progetto = _data.progetto;
      if (_data.fase) this.event.fase = _data.fase;
    }

    if (!this.event.stato) { this.eventForm?.disable() }

    if (_data.user) {
      this.event.nominativo = _data.user.nome + ' ' + _data.user.cognome;
      this.event.idUser = _data.user.id;
      console.log(this.event.idUser, 'utente calendario')
    };

  }

  ngOnInit(): void {
    this.progettoService.getProgetti()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.progetti = data as any[];
      });

    // Get natura progetti
    this.progettoService.getTipoNaturaProgetto()
      .pipe(take(1))
      .subscribe((data: any) => {
        this.tipiNaturaLista = data.tipo;
      });

    // Get sottotipologie
    this.infoService.getSottotipologie()
      .pipe(take(1))
      .subscribe(data => {
        this.listeSottotipologie = data;
      });

    if (this.event.idSBArea) {
      this.progettoService.getOggettoSB(this.event.natura, this.event.idSBArea)
        .pipe(take(1))
        .subscribe((data: any) => {
          if (this.event.natura == 'Marchio') this.descOggettoSB = data.nome;
          else this.descOggettoSB = data.titolo;
        });
    }

    if (this.event.progetto) {
      this.progettoService.getAllFasiByUser(this.event.progetto.id)
        .then(fasi => {
          this.fasi = fasi;
          //setTimeout(() => { this.eventForm.controls['fase'].setValue(this.eventForm.controls['fase'].value) }, 500);
        })
    }

    this.usersService.getUsers()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(users => {
        this.users = users;
      });

    this.eventForm = this.createEventForm();
    console.log('entro in modifica', this.eventForm);

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the event form
   *
   * @returns {FormGroup}
   */
  createEventForm(): UntypedFormGroup {
    return this._formBuilder.group({
      id: [this.event.id],
      // add data (idUser, ...)
      //progetto: [this.event.progetto, [RequireMatch]],
      natura: [this.event.natura],
      idSBArea: [this.event.idSBArea],
      sottotipologia: [this.event.sottotipologia],
      progetto: [this.event.progetto],
      fase: [this.event.fase],
      start: [this.event.start],
      startHour: [this.getHour(this.event.start), Validators.required],
      startMinutes: [this.getMinutes(this.event.start), Validators.required],
      end: [this.event.end],
      endHour: [this.getHour(this.event.end), Validators.required],
      endMinutes: [this.getMinutes(this.event.end), Validators.required],
      meta: this._formBuilder.group({
        notes: [this.event.meta.notes]
      }),
      scadenzario: [this.event.scadenzario],
      stato: [this.event.stato],
      user: [this.event.user],
      nominativo: [this.event.nominativo]
    });
  }

  getHour(date: Date): string {
    return date.getHours() != 0 ? date.getHours().toString() : '';
  }

  getMinutes(date: Date): string {
    return ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes());
  }

  dateChanged(event) {
    this.eventForm.controls['end'].setValue(event.value);
  }

  displayProgetto(progetto: any): string {
    return progetto && progetto.nome ? progetto.nome : '';
  }

  private _filterProgetto(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.progetti.filter(option => option.nome.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterFase(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.fasi.filter(option => option.nome.toLowerCase().indexOf(filterValue) === 0);
  }

  async onProgettoChanged(event) {
    console.log("Progetto change");
    console.log(event);

    const idProgetto = event.value.id;

    this.fasi = await this.progettoService.getAllFasiByUser(idProgetto);
    console.log(this.fasi);

    //setTimeout(() => { this.eventForm.controls['fase'].setValue(this.eventForm.controls['fase'].value) }, 500);
  }

  isNotNull<T>(value: T): value is NonNullable<T> {
    return value != null;
  }

  compareId(o1: any, o2: any) {
    return o1 && o2 ? o1.id == o2.id : false;
  }


  naturaRadioChange(event) {
    switch (event.value) {
      case 'Marchio':
        this.naturaSalva = 'marchi';
        this.sottotipologie = this.listeSottotipologie.MARCHI;
        break;
      case 'Brevetto':
        this.naturaSalva = 'brevetti';
        this.sottotipologie = this.listeSottotipologie.BREVETTI;
        break;
      case 'Design':
        this.naturaSalva = 'design';
        this.sottotipologie = this.listeSottotipologie.DESIGN;
        break;
      case 'Legal':
        this.naturaSalva = 'legal';
        this.sottotipologie = this.listeSottotipologie.LEGAL;
        break;
      default:
        break;
    }

    this.progettoService.getOggettiSB(this.naturaSalva, 'non presente')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.oggettiSB = data as any[];
      });
  }

  async onSottotipologiaChanged(event) {
    console.log('sottotipologia', event.value);
    console.log('natura', this.naturaSalva);
    const idSottoTipologia = event.value.id;

    this.progettoService.getOggettiSB(this.naturaSalva, event.value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        this.oggettiSB = data as any[];
      });

  }

}
