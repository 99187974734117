<mat-toolbar class="p-0 mat-elevation-z1">

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-24">
          <img width="140px" src="assets/images/logos/logoSB.png" routerLink="/home">
        </div>
      </div>

      <div class="px-8 px-md-24">
        <mat-icon class="ml-8 red-fg" *ngIf="notifiche">notifications_active</mat-icon>
        <!--  <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>-->
      </div>

    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <button *ngIf="studioService.studio.denominazione" mat-button [matMenuTriggerFor]="studioMenu"
        class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon *ngIf="studioService.studio.id=='EB'" style="color: #0e576b;" class="mr-sm-16 s-24 secondary-text">
            person
          </mat-icon>
          <mat-icon *ngIf="studioService.studio.id=='SRL'" style="color: #2d2da8;" class="mr-sm-16 s-24 secondary-text">
            work
          </mat-icon>
          <mat-icon *ngIf="studioService.studio.id=='STA'" style="color: #237826;" class="mr-sm-16 s-24 secondary-text">
            domain
          </mat-icon>

          <span *ngIf="studioService.studio.id=='EB'" style="color: #0e576b;" class="username mr-12" fxHide
            fxShow.gt-sm>{{ studioService.studio.denominazione }}</span>
          <span *ngIf="studioService.studio.id=='SRL'" style="color: #2d2da8;" class="username mr-12" fxHide
            fxShow.gt-sm>{{ studioService.studio.denominazione }}</span>
          <span *ngIf="studioService.studio.id=='STA'" style="color: #237826;" class="username mr-12" fxHide
            fxShow.gt-sm>{{ studioService.studio.denominazione }}</span>

          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #studioMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item route (click)="selezioneStudio()">
          <mat-icon>domain</mat-icon>
          <span>{{'toolbar.selectStudio' | translate}}</span>
        </button>

        <button mat-menu-item route [routerLink]="'/impostazioni/studio/'+ studioService.studio.id">
          <mat-icon>edit</mat-icon>
          <span>{{'toolbar.editStudio' | translate}}</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="mr-sm-16 s-24 secondary-text">account_circle</mat-icon>
          <span class="username mr-12" fxHide fxShow.gt-sm>{{ authService.loggedUser.nome }}
            {{ authService.loggedUser.cognome }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item route routerLink="/impostazioni/profilo">
          <mat-icon>account_circle</mat-icon>
          <span>{{'toolbar.profile' | translate}}</span>
        </button>

        <button mat-menu-item routerLink="/auth/change-password">
          <mat-icon>lock</mat-icon>
          <span>{{'toolbar.psw' | translate}}</span>
        </button>

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{'toolbar.logout' | translate}}</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <button class="language-button" style="color: #b7b70a;" mat-button fxHide fxShow.gt-xs route
        routerLink="/documentale">
        <mat-icon>folder</mat-icon>
      </button>

      <div class="toolbar-separator"></div>

      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
          <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{lang.title}}</span>
          </span>
        </button>

      </mat-menu>

    </div>

  </div>

</mat-toolbar>