<div class="dialog-content-wrapper">
  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{dialogTitle}}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>

    <form name="eventForm" [formGroup]="eventForm" class="event-form w-100-p" fxLayout="column" fxFlex>

      <!-- <mat-form-field appearance="outline" class="invisible">
        <mat-label>Id</mat-label>
        <input matInput name="id" formControlName="id" readonly>
      </mat-form-field> -->
      <span *ngIf="event.title" style="margin-bottom:10px">{{ event.title }}</span>

      <p *ngIf="action === 'edit'" style="margin-top: -10px; text-align: center;">
        <mat-checkbox formControlName="scadenzario" style="float: right;">Scadenzario</mat-checkbox>

        <b>{{ event.natura }}</b> {{ event.sottotipologia }} <span *ngIf="descOggettoSB"> - </span>
        {{ descOggettoSB }}
        <br>
      </p>

      <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
        <mat-radio-group *ngIf="action !== 'edit'" [formControl]="naturaFilter" #radio name="proprieta" fxFlex="40"
          (change)="naturaRadioChange($event)" style="margin-top: 20px; text-align: center;">
          <mat-radio-button ngDefaultControl formControlName="natura" name="natura" class="top-radio-button"
            color="primary" style="margin-right: 25px;" *ngFor="let item of tipiNaturaLista" [value]="item">
            {{item}}
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="action !== 'edit'" appearance="outline" floatLabel="always" fxFlex="40">
          <mat-label>{{'sottotipologia' | translate}}</mat-label>
          <mat-select formControlName="sottotipologia" placeholder="{{'sottotipologia' | translate}}" color="primary"
            name="sottotipologia" [compareWith]="compareId" (selectionChange)="onSottotipologiaChanged($event)">
            <mat-option *ngFor="let sottoTipo of this.sottotipologie" [value]="sottoTipo">
              {{sottoTipo}}
            </mat-option>
          </mat-select>
          <button mat-button matSuffix *ngIf="sottotipologiaFilter.value && sottotipologiaFilter.value.length"
            mat-icon-button aria-label="Clear" (click)="$event.stopPropagation(); sottotipologiaFilter.reset()">
            <mat-icon color="warn">close</mat-icon>
          </button>
        </mat-form-field>

        <mat-checkbox *ngIf="action !== 'edit'" fxFlex="20" formControlName="scadenzario"
          style="margin-top: 20px; margin-left: 20px;">Scadenzario </mat-checkbox>

      </div>

      <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="40">
          <mat-label>{{'Utente' | translate}}</mat-label>
          <mat-select formControlName="user" name="user" class="account-selection" [compareWith]="compareId"
            placeholder="cambia utente">
            <mat-option *ngFor="let user of this.users" [value]="user">{{user.nome}} {{user.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pr-sm-8" fxFlex="20">
          <mat-label>{{'data' | translate}}</mat-label>
          <input matInput [matDatepicker]="startDatePicker" name="start" formControlName="start"
            (dateChange)="dateChanged($event)">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pr-sm-8" floatLabel="always" fxFlex="10">
          <mat-label>{{'startHour' | translate}}</mat-label>
          <mat-select formControlName="startHour" name="startHour" required>
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pr-sm-8" floatLabel="always" fxFlex="10">
          <mat-label>{{'startMinutes' | translate}}</mat-label>
          <mat-select formControlName="startMinutes" name="startMinutes" required>
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="pr-sm-8" floatLabel="always" fxFlex="10">
          <mat-label>{{'endHour' | translate}}</mat-label>
          <mat-select formControlName="endHour" name="endHour" required>
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" fxFlex="10">
          <mat-label>{{'endMinutes' | translate}}</mat-label>
          <mat-select formControlName="endMinutes" name="endMinutes" required>
            <mat-option *ngFor="let minute of minutes" [value]="minute">
              {{minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" *ngIf="action !== 'edit' && oggettiSB">
        <mat-label>{{ naturaFilter.value }}</mat-label>
        <mat-select formControlName="idSBArea" name="idSBArea" [compareWith]="compareId">
          <mat-option *ngFor="let oggetto of this.oggettiSB" [value]="oggetto.id">
            <div *ngIf="naturaFilter.value == 'Marchio'" style="font-size: smaller;">{{oggetto.nome}}</div>
            <div *ngIf="naturaFilter.value == 'Brevetto'" style="font-size: smaller;">{{oggetto.titolo}}</div>
            <div *ngIf="naturaFilter.value == 'Design'" style="font-size: smaller;">{{oggettotitolo}}</div>
          </mat-option>
        </mat-select>
        <button mat-button matSuffix *ngIf="eventForm.controls['idSBArea'].value" mat-icon-button
          (click)="$event.stopPropagation();eventForm.controls['idSBArea'].reset();">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </mat-form-field>


      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" *ngIf="progetti">
        <mat-label>{{'progetto' | translate}}</mat-label>
        <mat-select formControlName="progetto" name="progetto" [compareWith]="compareId"
          (selectionChange)="onProgettoChanged($event)">
          <mat-option *ngFor="let progetto of this.progetti" [value]="progetto">
            <div style="font-size: smaller;">{{progetto.nome}}</div>
          </mat-option>
        </mat-select>
        <button mat-button matSuffix *ngIf="eventForm.controls['progetto'].value" mat-icon-button
          (click)="$event.stopPropagation();eventForm.controls['progetto'].reset();">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100" *ngIf="fasi">
        <mat-label>{{'fase' | translate}}</mat-label>
        <mat-select formControlName="fase" name="fase" [compareWith]="compareId">
          <mat-option *ngFor="let fase of this.fasi" [value]="fase">
            <div style="font-size: smaller;">{{ fase.descrizione }}</div>
          </mat-option>
        </mat-select>
        <button mat-button matSuffix *ngIf="eventForm.controls['fase'].value" mat-icon-button
          (click)="$event.stopPropagation(); eventForm.controls['fase'].reset();">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </mat-form-field>



      <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">

        <mat-form-field appearance="outline" fxFlex="100" class="invisible">
          <input matInput [matDatepicker]="endDatePicker" name="end" formControlName="end">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

      </div>

      <mat-form-field appearance="outline" formGroupName="meta" class="w-100-p">
        <mat-label>{{'note' | translate}}</mat-label>
        <textarea matInput formControlName="notes" mat-maxlength="250" max-rows="4">
                </textarea>
      </mat-form-field>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="action ==='edit'" mat-button color="primary" class="mr-8"
      (click)="matDialogRef.close(['delete',eventForm])" aria-label="Delete" matTooltip="Delete"
      [disabled]="!event.stato">
      Cancella
    </button>

    <button *ngIf="action !== 'edit'" mat-button color="primary" class="save-button"
      (click)="matDialogRef.close(eventForm)" [disabled]="eventForm.invalid || !event.stato" aria-label="ADD">
      Aggiungi
    </button>

    <button *ngIf="action === 'edit'" mat-button color="primary" class="save-button"
      (click)="matDialogRef.close(['save', eventForm])" [disabled]="eventForm.invalid || !event.stato"
      aria-label="SAVE">
      Salva
    </button>

  </div>
</div>