import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mapNazioni' })
export class MapNazioniPipe implements PipeTransform {

  constructor() { }

  transform(nazioni: any = []): any {
    return nazioni.map(n => n.descrizioneIT);
  }
}
