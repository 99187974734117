import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { NazioniService } from 'app/main/impostazioni/nazioni/nazioni.service';
import { User } from 'app/main/impostazioni/users/model/user.model';
import { UsersService } from 'app/main/impostazioni/users/users.service';
import { TodoService } from 'app/main/todo/todo.service';
import { LocalStorageService } from 'app/shared/service/localStorage.service';
import { UtilService } from 'app/shared/service/util.service';
import { EmailAuthProvider } from 'firebase/auth';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  cliente: boolean = false;
  studioAdmin: boolean = false;
  attivo: boolean = false;

  loggedUser: User = new User();

  onLoggedUserChanged: BehaviorSubject<any>;

  public msgErrore: string;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public dialog: MatDialog,
    public utilService: UtilService,
    public usersService: UsersService,
    private nazioniService: NazioniService,
    public localStorageService: LocalStorageService,
    public _todoService: TodoService,
    private _fuseNavigationService: FuseNavigationService,

    private db: AngularFirestore
  ) {
    this.onLoggedUserChanged = new BehaviorSubject({});

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));

        // TODO - Implementare gestione dei permessi
        this.user.getIdTokenResult(true).then((token) => {
          this.cliente = false;
          if (token.claims.attivo)
            this.attivo = true;

          if (token.claims.studioAdmin)
            this.studioAdmin = true;

          this._todoService.getNrNotifiche(); // Get notifiche

          //butto fuori se l'utente non è abilitato
          if (!this.attivo && user.emailVerified) {
            this.router.navigate(['auth/non-attivo']);
            this.afAuth.signOut();
          }

          this.setNavbarView();
        });

        this.usersService.getUserById(user.uid).then((user) => {
          this.loggedUser = user;
          this.onLoggedUserChanged.next(this.loggedUser);
          localStorage.setItem('loggedUser', JSON.stringify(user));
        });
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async login(email: string, password: string) {
    let user;

    try {
      user = await this.afAuth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      this.msgErrore = 'Email e/o Password errati';
      console.error('Utente o Password errati:', error.message);
      return;
    }

    if (user && user.user.emailVerified) {
      this.msgErrore = undefined;
      this.router.navigate(['home']);

      this.nazioniService.getNazioni()
        .pipe(take(1))
        .subscribe(data => this.localStorageService.set('nazioni', data))

    } else {
      await this.router.navigate(['auth/mail-confirm']);
      this.afAuth.signOut();
    }
  }

  async register(data: any) {
    return await this.afAuth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((credential) => {
        //Inserimento nella tabella users dell'utente appena creato
        this.db.collection('users').doc(credential.user.uid)
          .set({
            nome: data.nome,
            cognome: data.cognome,
            codiceFiscale: data.codiceFiscale,
            fax: data.fax,
            telefono: [data.telefono],
            cellulare: [data.cellulare],
            email: data.email,
            pec: data.pec,
            registrato: true,
          })
          .then(() => {
            this.sendEmailVerification();
          });
      })
      .catch((error) => {
        console.error('Errore durante la registrazione: ' + error.message);
        return error.message;
      });
  }

  async sendEmailVerification() {
    (await this.afAuth.currentUser).sendEmailVerification();
    this.router.navigate(['auth/mail-confirm']).then(() => {
      this.afAuth.signOut();
    });
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    await this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('studio');
      this.router.navigate(['auth/login']);
    });
  }

  async changePassword(password: string, newPassword: string) {
    const user = await this.afAuth.currentUser;
    const credentials = EmailAuthProvider.credential(user.email, password);

    return user.reauthenticateWithCredential(credentials)
      .then(() => { return user.updatePassword(newPassword) });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }


  setNavbarView(): void {
    // Nascondo dalla Navbar i collegamenti che l'utente non può modificare
    // TODO - Da aggiornare quando si avranno tutti le funzionalità esatte per ogni utente

    this.user.getIdTokenResult(true).then((token) => {
      this._fuseNavigationService.updateNavigationItem('users', {
        hidden: !(this.studioAdmin && this.attivo),
      });

      this._fuseNavigationService.updateNavigationItem('marchi', {
        hidden: !(
          (token.claims.studioAdminTrademarks ||
            token.claims.studioAdmin || token.claims.studioAdminSegreteria || this.cliente) && this.attivo
        ),
      });
      this._fuseNavigationService.updateNavigationItem('brevetti', {
        hidden: !(
          (token.claims.studioAdminPatents ||
            token.claims.studioAdmin || token.claims.studioAdminSegreteria || this.cliente) && this.attivo
        ),
      });
      this._fuseNavigationService.updateNavigationItem('design', {
        hidden: !(
          (token.claims.studioAdminDesign ||
            token.claims.studioAdmin || token.claims.studioAdminSegreteria || this.cliente) && this.attivo
        ),
      });
      this._fuseNavigationService.updateNavigationItem('legal', {
        hidden: !(
          (token.claims.studioAdminLegal ||
            token.claims.studioAdmin || token.claims.studioAdminSegreteria || this.cliente) && this.attivo
        ),
      });
      this._fuseNavigationService.updateNavigationItem('progetti', {
        hidden: !((token.claims.studioAdmin || token.claims.studioAdminSegreteria) && this.attivo)
      });
      this._fuseNavigationService.updateNavigationItem('clienti', {
        hidden: !((token.claims.studioAdmin || token.claims.studioAdminSegreteria) && this.attivo)
      });
      this._fuseNavigationService.updateNavigationItem('rubrica', {
        hidden: !((token.claims.studioAdmin || token.claims.studioAdminSegreteria) && this.attivo)
      });
      this._fuseNavigationService.updateNavigationItem('fornitori', {
        hidden: !((token.claims.studioAdmin || token.claims.studioAdminSegreteria) && this.attivo)
      });
      this._fuseNavigationService.updateNavigationItem('documentale', {
        hidden: !((token.claims.studioAdmin || token.claims.studioAdminSegreteria) && this.attivo)
      });

      this._fuseNavigationService.updateNavigationItem('contabilita', {
        hidden: !(this.studioAdmin && this.attivo),
      });

      this._fuseNavigationService.updateNavigationItem('listino', {
        hidden: !(this.studioAdmin && this.attivo),
      });

    });
  }
}
