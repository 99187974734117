import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { deleteField } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StudioService } from 'app/main/impostazioni/studio/studio.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpesaService implements Resolve<any>{
  spesa: any;
  routeParams: any;
  onSpesaChanged: BehaviorSubject<any>;

  constructor(
    private db: AngularFirestore,
    private studioService: StudioService
  ) {
    // Set the defaults
    this.onSpesaChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getSpesa(this.routeParams.id)
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get spese
   *
   * Prende tutte le spese dalla tabella spese.
   */
  getSpese() {
    console.log(this.studioService.studio.id);
    return this.db.collection('spese', ref => ref.where('studio', '==', this.studioService.studio.id).where('fittizia', '==', false)).valueChanges({ idField: 'id' });
  }

  /**
   * Get spese by Fase
   *
   * Prende tutte le spese dalla tabella spese.
   */
  getSpeseByFase(idFase: string, fittizia: boolean) {
    return this.db.collection('spese', ref => ref.where('fase.id', '==', idFase).where('fittizia', '==', fittizia)).valueChanges({ idField: 'id' });
  }

  getAllSpeseByFase(idFase: string) {
    return this.db.collection('spese', ref => ref.where('fase.id', '==', idFase)).valueChanges({ idField: 'id' });
  }

  getSpeseByFasi(idFasi: string[]) {
    return this.db.collection('spese', ref => ref.where('fase.id', 'in', idFasi)).valueChanges({ idField: 'id' });
  }

  /**
   * Get spesa
   *
   * Prende una sola spesa in base all'id.
   * @param {string} id
   */
  getSpesa(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (id === 'new') {
        this.onSpesaChanged.next(false);
        resolve(false);
      }
      else {
        const doc = this.db.doc<any>('spese/' + id).valueChanges()
          .subscribe(selezione => {
            if (selezione) {

              this.spesa = selezione;
              this.spesa.id = id; //metto l'id passato

              //converto le date per la visualizzazione
              if (selezione.dataPagamento) {
                this.spesa.dataPagamento = new Date(selezione.dataPagamento?.seconds * 1000);
              }
            }

            this.onSpesaChanged.next(this.spesa);
            resolve(selezione);
          }, reject);
      }
    });
  }

  /**
   * Get spesa riconciliata
   *
   * Prende una sola spesa in base all'id.
   * @param {string} id
   */
  getSpesaRiconciliata(id: string) {
    console.log('id passato ' + id)
    return this.db.doc<any>('spese/' + id).valueChanges({ idField: 'id' });
  }

  getSpeseNonRiconciliate(pivaFornitore) {
    console.log('prima del DB: ' + pivaFornitore);
    return this.db.collection("spese", ref => ref.where('riconciliato', '==', false).where('fornitore.partitaIva', '==', pivaFornitore.toString())).valueChanges({ idField: 'id' });
  }

  getBancaRiconciliata(id: string) {
    return this.db.doc<any>('banca/' + id).valueChanges({ idField: 'id' });
  }

  forzaRiconciliazioneSenzaFattura(idSpesa) {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    const refSpese = this.db.firestore.collection("spese").doc(idSpesa);

    return this.db.firestore.runTransaction((transaction) => {
      return transaction.get(refSpese).then((spesa) => {
        if (!spesa.exists) {
          throw "Document does not exist!";
        }
        transaction.update(refSpese, { riconciliato: true, 'chiaveRiconciliazione': '', 'fattura.utente': loggedUser.nome + ' ' + loggedUser.cognome, 'fattura.dataElaborazione': new Date(), 'fattura.tipoRiconciliazione': 'forzata' });
      });
    });
  }


  eliminaRiconciliazioneForzata(idSpesa, idFattura) {
    console.log('spesa ' + idSpesa);
    console.log('idFattura ' + idFattura);

    const refFattura = this.db.firestore.collection("fatture-ricevute").doc(idFattura);
    const refSpesa = this.db.firestore.collection("spese").doc(idSpesa);

    return this.db.firestore.runTransaction((transaction) => {
      return transaction.get(refSpesa).then((spesa) => {
        console.log('entra 1 ');

        if (!spesa.exists) {
          throw "Document does not exist!";
        }

        return transaction.get(refFattura).then((fattura) => {
          if (fattura.exists) {
            transaction.update(refFattura, { riconciliato: false, 'chiaveRiconciliazione': deleteField(), 'spesa.id': deleteField(), 'spesa.utente': deleteField(), 'spesa.dataElaborazione': deleteField(), 'spesa.tipoRiconciliazione': deleteField() });
            transaction.update(refSpesa, { riconciliato: false, 'chiaveRiconciliazione': deleteField(), 'numeroFatturaAcquisto': deleteField(), 'fattura.id': deleteField(), 'fattura.utente': deleteField(), 'fattura.dataElaborazione': deleteField(), 'fattura.tipoRiconciliazione': deleteField() });
          }
          else {
            transaction.update(refSpesa, { riconciliato: false, 'chiaveRiconciliazione': deleteField(), 'numeroFatturaAcquisto': deleteField(), 'fattura.id': deleteField(), 'fattura.utente': deleteField(), 'fattura.dataElaborazione': deleteField(), 'fattura.tipoRiconciliazione': deleteField() });
          }

        })
      });
    });
  }

  /**
   * Add spesa
   *
   * @param spesa
   */
  addSpesa(spesa) {
    const id = spesa.id;
    delete spesa.id;

    spesa.studio = this.studioService.studio.id;
    spesa.stato = 0;
    if (spesa.modalitaPagamento) delete spesa.modalitaPagamento.stato;
    if (spesa.dataPagamento) { spesa.dataPagamento = new Date(spesa.dataPagamento); }

    return this.db.collection('spese').doc(id).set({ ...spesa });
  }

  addSpesaFittizia(spesa, titolare, attivita) {
    delete spesa.id;
    spesa.studio = this.studioService.studio.id;
    spesa.stato = 0;

    var batch = this.db.firestore.batch();

    const spesaRef = this.db.collection("spese").doc().ref;
    batch.set(spesaRef, spesa)

    for (const att of attivita) {
      const attivitaRef = this.db.collection("clienti").doc(titolare).collection('attivita').doc(att).ref;
      batch.update(attivitaRef, { stato: true })
    }

    return batch.commit();
  }


  updateSpesa(spesa): Promise<any> {
    const id = spesa.id;
    delete spesa.id;

    if (spesa.dataPagamento) { spesa.dataPagamento = new Date(spesa.dataPagamento); }

    return this.db.doc('spese/' + id).update(spesa);
  }

  deleteSpesa(spesa) {
    return this.db.collection('spese').doc(spesa.id).delete();
  }

  removeFields(id: string, fields: string[]) {
    const batch = this.db.firestore.batch();
    const speseRef = this.db.collection('spese').doc(id).ref;
    fields.forEach(field => batch.update(speseRef, { [field]: deleteField() }));
    return batch.commit();
  }
}

