<div id="calendar" class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div *ngIf="parent != 'todo'" class="header p-16 p-sm-24" [ngClass]="viewDate | date:'MMM'">

    <div class="header-content accent" fxLayout="column" fxLayoutAlign="space-between">

      <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">

        <div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            today
          </mat-icon>
          <span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
            Agenda - Scadenzario
          </span>

          <!-- <div>
            <button mat-raised-button class="ml-8" (click)="updateTimesheet()">Update Timesheet</button>
            <button mat-raised-button class="ml-8" (click)="lockTimesheet()">Lock Eventi</button>
          </div> -->

        </div>

        <!-- TOOLBAR -->
        <div class="toolbar" fxLayout="row" fxLayoutAlign="start center">

          <button mat-icon-button aria-label="Search" matTooltip="Search">
            <mat-icon>search</mat-icon>
          </button>

          <button mat-icon-button mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="selectedDay = {date:$event}" aria-label="Today" matTooltip="Today">
            <mat-icon>today</mat-icon>
          </button>

          <button mat-icon-button (click)="view='day'" aria-label="Day" matTooltip="Day">
            <mat-icon>view_day</mat-icon>
          </button>

          <button mat-icon-button (click)="view='week'" aria-label="Week" matTooltip="Week">
            <mat-icon>view_week</mat-icon>
          </button>

          <button mat-icon-button (click)="view='month'" aria-label="Month" matTooltip="Month">
            <mat-icon>view_module</mat-icon>
          </button>
        </div>
      </div>
      <!-- / TOOLBAR -->

      <div class="header-mid accent" *ngIf="this.authService.studioAdmin">
        <mat-form-field appearance="fill" fxFlex="20">
          <mat-label>{{'Utente' | translate}}</mat-label>
          <mat-select [formControl]="user" class="account-selection" placeholder="cambia utente">
            <mat-option *ngFor="let item of users" [value]="item">{{item.nome}} {{item.cognome}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <section style="margin-left: 20px; margin-top: 20px;">
          <mat-checkbox (click)="vediScadenzario()" [(ngModel)]="checked">Scadenzario generale</mat-checkbox>
        </section>

      </div>

      <!-- HEADER BOTTOM -->
      <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center"
        [@animate]="{value:'*',params:{delay:'150ms'}}">

        <button mat-icon-button class="arrow" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="selectedDay = {date:$event}" aria-label="Previous">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <div class="title">
          {{ viewDate | calendarDate:(view + 'ViewTitle'):'it':1 }}
        </div>

        <button mat-icon-button class="arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="selectedDay = {date:$event}" aria-label="Next">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <!-- / HEADER BOTTOM -->
    </div>

    <!-- ADD EVENT BUTTON -->
    <button mat-fab class="add-event-button mat-accent" (click)="addEvent()" aria-label="Add event"
      [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
      <mat-icon>add</mat-icon>
    </button>
    <!-- / ADD EVENT BUTTON -->
  </div>
  <!-- / HEADER -->

  <div *ngIf="parent == 'todo'" class="header-bottom" fxLayout="row" fxLayoutAlign="center center"
    [@animate]="{value:'*',params:{delay:'150ms'}}">

    <button mat-icon-button class="arrow" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
      (viewDateChange)="selectedDay = {date:$event}" aria-label="Previous">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="title">
      {{ viewDate | calendarDate:(view + 'ViewTitle'):'it':1 }}
    </div>

    <button mat-icon-button class="arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
      (viewDateChange)="selectedDay = {date:$event}" aria-label="Next">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <!-- CONTENT -->
  <div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen" [weekStartsOn]="1" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="editEvent('edit', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
        (beforeViewRender)="beforeMonthViewRender($event)" [cellTemplate]="customCellTemplate" [locale]="locale">
      </mwl-calendar-month-view>

      <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
          <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
        <div class="title-card" *ngFor="let event of day.events">
          <small>{{ event.title }} - {{ event.progetto.nome }} - {{ event.fase.descrizione }}</small>
        </div>
        <!--<small style="margin: 5px;">There are {{ day.events.length }} events on this day</small>-->
      </ng-template>

      <div>

      </div>
      <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventClicked)="editEvent('edit', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
        [hourSegments]="2" [hourSegmentHeight]="30" [weekStartsOn]="1" [dayStartHour]="8" [dayEndHour]="20"
        [eventTemplate]="eventTemplate" [locale]="locale" [excludeDays]="excludeDays">
      </mwl-calendar-week-view>

      <ng-template #eventTemplate let-weekEvent="weekEvent" let-locale="locale" let-eventClicked="eventClicked"
        let-tooltipPlacement="tooltipPlacement" let-tooltipTemplate="tooltipTemplate"
        let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDisabled="tooltipDisabled" let-style="style">
        <div class="cal-event" (mwlClick)="eventClicked.emit({ event: weekEvent.event })"
          [ngStyle]="weekEvent.event.scadenzario ? {'background-color': '#ffb380'} : {'color':'black'}">
          <p><mat-icon class="s-20" *ngIf="weekEvent.event.user"
              title="{{ weekEvent.event.user.nome }} {{ weekEvent.event.user.cognome }}">perm_identity</mat-icon>{{
            weekEvent.event.title }} {{ weekEvent.event.start | date:'shortTime' }} - {{ weekEvent.event.end |
            date:'shortTime' }} {{ weekEvent.event.natura }} {{ weekEvent.event.sottotipologia }} </p>
          <span class="cal-event-title">
            {{ weekEvent.event.progetto.nome }}
          </span>
          <span class="cal-event-title">
            {{ weekEvent.event.fase.descrizione }}
          </span>
        </div>
      </ng-template>

      <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        [hourSegments]="2" [hourSegmentHeight]="30" [dayStartHour]="8" [dayEndHour]="20"
        (eventClicked)="editEvent('edit', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view>
    </div>
  </div>
  <!-- / CONTENT -->
</div>