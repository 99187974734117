import { NgModule } from '@angular/core';
import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';

@NgModule({
  imports: [
    /*VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,*/

    HorizontalLayout1Module
  ],
  exports: [
    /*VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,*/

    HorizontalLayout1Module
  ]
})
export class LayoutModule {
}
