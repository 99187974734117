export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'IMPOSTAZIONI': 'Settings',
            'SBAREA': 'SBarea',
            'REGISTRO': 'Register',
            'STATUS': 'Status',
            'MARCHI': 'Trademarks',
            'BREVETTI': 'Patents',
            'DESIGN': 'Design',
            'LEGAL': 'Legal',
            'AZIENDE': 'Companies',
            'TABELLE': 'Table',
            'UTENTI': 'Users',
            'SAMPLE': {
                'TITLE': 'Sample',
                'BADGE': '25'
            }
        }
    }
};
