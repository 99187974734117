import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'inArray' })
export class InArrayPipe implements PipeTransform {
    /**
     * Transforma l'oggetto in array così è possibile utilizzarlo nella direttiva ngFor di un oggetto
     */
    transform(objects: any = []): any[] {
        return Object.values(objects);
    }
}
