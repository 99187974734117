import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard'; //serve x verifica se l'utente è loggato
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { DateTime } from 'luxon';
import moment from 'moment';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './core/material/material.module';
import { CanDeactivateGuard } from './guard/can-deactivate-guard.service';
import { CalendarModule } from './main/agenda/agenda.module';
import { SelezioneStudioComponent } from './main/impostazioni/studio/selezione-studio/selezione-studio.component';
import { AvvisoComponent } from './shared/dialog/avviso/avviso.component';
import { getItPaginatorIntl } from './shared/it-paginator-intl';
import { PipesModule } from './shared/pipes/pipes.module';

console.warn("App module loaded");

registerLocaleData(localeIt);

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date): string {
    return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
  }

  parse(value: any): Date | null {
    if (!moment(value, 'DD/MM/YYYY', true).isValid()) {
      return this.invalid();
    }
    return moment(value, 'DD/MM/YYYY', true).toDate();
  }
}


@NgModule({
  declarations: [
    AppComponent,
    AvvisoComponent,
    SelezioneStudioComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    // Material moment date module
    MatNativeDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    // App modules
    LayoutModule,
    CalendarModule,
    //LegalModule, // TODO - Capire cosa farne
    //FileManagerModule, // TODO - Capire cosa farne
    MaterialModule,
    AppRoutingModule,
    PipesModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    CanDeactivateGuard,
    { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: REGION, useValue: 'europe-west6' },
    { provide: MatPaginatorIntl, useValue: getItPaginatorIntl() }
  ]
})
export class AppModule {
}
