import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Pipe({ name: 'getDownloadUrl' })
export class GetDownloadUrlPipe implements PipeTransform {

  constructor(
    private storage: AngularFireStorage
  ) { }

  /**
   * Mi recupero il download url di un file sullo storage
   */
  async transform(path: any = []): Promise<string> {
    let url = "";

    if (path) {
      try {
        url = await this.storage.storage.ref(path).getDownloadURL();
      } catch (error) {
        console.error(error);
      }
    }
    return url;
  }
}
