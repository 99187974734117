import { Claims } from "./claims.model";

export class User {
  id: string;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  email: string;
  pec: string;
  fax: string;
  telefono: string[];
  cellulare: string[];
  claims: Claims;

  constructor(user?) {
    user = user || {};
    this.id = user.id || '';
    this.nome = user.nome || '';
    this.cognome = user.cognome || '';
    this.codiceFiscale = user.codiceFiscale || '';
    this.email = user.email || '';
    this.pec = user.pec || '';
    this.fax = user.fax || '';
    this.telefono = user.telefono || [];
    this.cellulare = user.cellulare || [];
    this.claims = user.claims || '';
  }
}