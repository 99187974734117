export const locale = {
  lang: 'it',
  data: {
    'NAV': {
      'APPLICATIONS': 'Applications',
      'IMPOSTAZIONI': 'Impostazioni',
      'SBAREA': 'SBarea',
      'NAZIONE': 'Nazioni',
      'STATUS': 'Status',
      'SOTTOFASI': 'Sottofasi',
      'PROGETTI': 'Progetti',
      'MARCHI': 'Marchi',
      'BREVETTI': 'Brevetti',
      'DESIGN': 'Design',
      'LEGAL': 'Legal',
      'AZIENDE': 'Aziende',
      'CLIENTI': 'Clienti',
      'CORRISPONDENTI': 'Corrispondenti',
      'FORNITORI': 'Fornitori',
      'RUBRICA': 'Rubrica',
      'DOCUMENTALE': 'Modelli documenti',
      'AGENDA': 'Agenda',
      'NOTIFICHE': 'Notifiche',
      'TABELLE': 'Tabelle',
      'UTENTI': 'Utenti',
      'CREAZIONE': 'Creazione',
      'FASIDEF': 'Fasi',
      'TEMPLATE': 'Template progetti',
      'TIMESHEET': 'Timesheet',
      'SPESE': 'Spese',
      'MODELLISPESE': 'Modelli spese',
      'CARICAMENTOSDI': 'Caricamento SDI',
      'CARICAMENTONONSDI': 'Caricamento non SDI',
      'REGISTROSPESE': 'Registro spese',
      'MODALITAPAGAMENTO': 'Modalità pagamenti',
      'VOCISPESA': 'Voci di spesa',
      'CATEGORIESPESE': 'Categorie spese',
      'MARCHEDABOLLO': 'Marche da bollo',
      'SPESEDEFAULT': 'Gestione spese',
      'CONTABILITA': 'Contabilità',
      'PREVENTIVI': 'Preventivi',
      'LISTINI': 'Listini',
      'LISTINOMARCHI': 'Listino marchi',
      'LISTINOBREVETTI': 'Listino brevetti',
      'LISTINOCLIENTE': 'Listino cliente',
      'ALIQUOTAIVA': 'Aliquote iva',
      'TAGS': 'Gestione tags',
      'TAGSFILE': 'Gestione tags file',
      'TAGSCORR': 'Gestione tags corrispondenza',
      'FATTPROPOSTE': 'Fatture Proposte',
      'FATTINVIATE': 'Fatture Emesse',
      'FATTRICEVUTE': 'Fatture Ricevute',
      'RICONCILIAZIONE': 'Riconciliazione bancaria',
      'VALUTE': 'Valute',
      'SAMPLE': {
        'TITLE': 'Esempio',
        'BADGE': '25'
      }
    }
  }
};
