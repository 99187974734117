import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class SnackbarService {

  constructor(public snackBar: MatSnackBar) { }

  public open(message, position, duration, style) {
    this.snackBar.open(message, "OK", {
      verticalPosition: position,
      duration: duration,
      panelClass: [style]
    });
  }
}
