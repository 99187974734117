export const locale = {
  lang: 'it',
  data: {
    'toolbar': {
      'selectStudio': 'Selezione studio',
      'editStudio': 'Modifica studio',
      'profile': 'Profilo',
      'psw': 'Cambia password',
      'logout': 'Esci'
    }
  }
};
