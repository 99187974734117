import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudioService } from '../studio.service';

@Component({
  selector: 'selezione-studio',
  templateUrl: './selezione-studio.component.html',
  styleUrls: ['./selezione-studio.component.scss']
})
export class SelezioneStudioComponent {
  title: string;
  message: string;
  studio: any;
  studi: any;

  constructor(
    private studioService: StudioService,
    public dialogRef: MatDialogRef<SelezioneStudioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.studioService.getStudi()
      .subscribe(studi => {
        this.studi = studi;
      })
  }
}
