export const locale = {
  lang: 'en',
  data: {
    'toolbar': {
      'selectStudio': 'Select studio',
      'editStudio': 'Edit studio',
      'profile': 'Profile',
      'psw': 'Change password',
      'logout': 'Logout'
    }
  }
};
