import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { arrayRemove, arrayUnion, documentId } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StudioService } from 'app/main/impostazioni/studio/studio.service';
import { SpesaService } from 'app/main/spese/spesa/spesa.service';
import * as _ from 'lodash';
import { pick } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { Progetto } from './model/progetto.model';

@Injectable({
  providedIn: 'root'
})
export class ProgettoService implements Resolve<any>{
  progetto: any;
  gruppo: any;
  fase: any;
  routeParams: any;

  onProgettoChanged: BehaviorSubject<any>;
  onGruppoChanged: BehaviorSubject<any>;
  onFaseChanged: BehaviorSubject<any>;
  onItemChanged: BehaviorSubject<any>

  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions,
    public studioService: StudioService
  ) {
    // Set the defaults
    this.onProgettoChanged = new BehaviorSubject({});
    this.onGruppoChanged = new BehaviorSubject({});
    this.onFaseChanged = new BehaviorSubject({});
    this.onItemChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this.getProgetto(this.routeParams.pid)
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  get() {
    return this.onProgettoChanged.getValue();
  }

  /**
   * Get progetti
   */
  getProgetti(params?) {

    let query = this.db.collection('progetti').ref
      .where('studio', '==', this.studioService.studio.id)
      .orderBy('nome');

    //return this.db.collection('confessions', ref => query).valueChanges();
    return this.db.collection('progetti', ref => query).valueChanges({ idField: 'id' });
  }

  // Get con paginazione
  getProgettiPaginate(params?) {

    console.log(params);

    return this.db.collection('progetti', ref => {
      let query = ref.where('studio', '==', this.studioService.studio.id)
        .orderBy(documentId());

      if (params.stato) {
        query = query.where('stato.id', '==', params.stato);
      }

      if (params.sottoprogetto) {
        query = query.where('sottoprogetto', '==', true);
      }

      if (params.back) {
        query = query.endBefore(params.back.id).limitToLast(params.limit);
      } else {
        query = query.limit(params.limit);
      }

      if (params.next) {
        query = query.startAfter(params.next.id);
      }

      //query = query.orderBy('nome');

      return query;
    }).valueChanges({ idField: 'id' })


    //return this.db.collection('progetti', ref => query).valueChanges({ idField: 'id' });


  }

  /**
   * Get template progetti
   */
  getTemplateProgetti() {
    return this.db.collection('template-progetti').valueChanges({ idField: 'id' });
  }

  getTemplateProgettiByNatura(natura) {
    return this.db.collection('template-progetti', ref => ref.where('natura', '==', natura)).valueChanges({ idField: 'id' });
  }

  getTemplateGruppo(id: string) {
    return this.db.collection('template-gruppi').doc(id).ref.get();
  }

  getTemplateFase(id: string) {
    return this.db.collection('template-fasi').doc(id).ref.get();
  }

  /**
   * Get progetto
   */
  getProgetto(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams && this.routeParams.pid === 'new') {
        this.onProgettoChanged.next(false);
        resolve(false);
      } else {
        this.db.doc<any>('progetti/' + id).valueChanges()
          .subscribe(selezione => {
            if (selezione) {
              this.progetto = selezione;
              this.progetto.id = id; //metto l'id passato

              // TODO - modificare gestione delle date

              //if (selezione.dataCreazione) this.progetto.dataCreazione = new Date(selezione.dataCreazione?.seconds * 1000);
              if (selezione.dataCreazione) this.progetto.dataCreazione = selezione.dataCreazione.toDate();
              if (selezione.inizioSorveglianza) this.progetto.inizioSorveglianza = new Date(selezione.inizioSorveglianza?.seconds * 1000);
              if (selezione.fineSorveglianza) this.progetto.fineSorveglianza = new Date(selezione.fineSorveglianza?.seconds * 1000);

              // Rilievo
              if (selezione.dataRilievo) { selezione.dataRilievo = new Date(selezione.dataRilievo?.seconds * 1000); }
              if (selezione.scadenzaRispostaRilievo) { selezione.scadenzaRispostaRilievo = new Date(selezione.scadenzaRispostaRilievo?.seconds * 1000); }

              // Rifiuto
              if (selezione.dataRifiuto) { selezione.dataRifiuto = new Date(selezione.dataRifiuto?.seconds * 1000); }
              if (selezione.scadenzaRispostaRifiuto) { selezione.scadenzaRispostaRifiuto = new Date(selezione.scadenzaRispostaRifiuto?.seconds * 1000); }

              if (selezione.tettiMassimi) selezione.tettiMassimi.forEach(tetto => tetto.data = tetto.data.toDate());
            }

            this.onProgettoChanged.next(this.progetto);
            resolve(selezione);

          }, reject);
      }
    });

  }

  /**
   * Update progetto
   */
  updateProgetto(progetto: any): Promise<any> {
    let batch = this.db.firestore.batch();

    let id = this.routeParams.pid;

    console.log(progetto);

    // Rilievo
    if (progetto.dataRilievo) { progetto.dataRilievo = new Date(progetto.dataRilievo); }
    if (progetto.scadenzaRispostaRilievo) { progetto.scadenzaRispostaRilievo = new Date(progetto.scadenzaRispostaRilievo); }

    // Rifiuto
    if (progetto.dataRifiuto) { progetto.dataRifiuto = new Date(progetto.dataRifiuto); }
    if (progetto.scadenzaRispostaRifiuto) { progetto.scadenzaRispostaRifiuto = new Date(progetto.scadenzaRispostaRifiuto); }


    // Edit descrizione fase per sottoprogetto
    if (progetto.sottoprogetto) {
      const fasePadreRef = this.db.collection('progetti/' + progetto.progettoPadre + '/gruppi/' + progetto.gruppoPadre + '/fasi/').doc(progetto.fasePadre).ref;
      batch.update(fasePadreRef, { descrizione: progetto.nome });

      console.log(progetto.classi);
      console.log(progetto.nazione);

      progetto.classi.forEach(classe => {
        const frazioneRef = this.db.collection('marchi/' + progetto.idMarchio + '/registrazioni/').doc('' + progetto.nazione.id + classe).ref;
        batch.update(frazioneRef, { stato: progetto.statoFrazioni });
      });
    }

    delete progetto.id;
    delete progetto.nazione;
    progetto.referente = _.pick(progetto.referente, ['id', 'nome', 'cognome']); // Edit referente, lascio solamente id, nome, cognome


    const progettoRef = this.db.doc('progetti/' + id).ref;
    batch.update(progettoRef, progetto);

    return batch.commit();
  }

  chiudiProgetto(idProgetto: string, exOfficio?: string) {
    const stato30ref = this.db.collection('stati-progetto').doc('30').ref;

    return this.db.firestore.runTransaction(async (transaction) => {
      const stato30doc = await transaction.get<any>(stato30ref);

      if (!stato30doc.exists) {
        throw "Document does not exist!";
      }

      let progettoRef = this.db.firestore.collection('progetti').doc(idProgetto);
      transaction.update(progettoRef, { stato: { id: stato30doc.id, desc: stato30doc.data().desc } });
      if (exOfficio) transaction.update(progettoRef, { exOfficio: exOfficio });
    });

  }

  /**
   * Delete progetto
   */
  deleteProgetto(id: string) {
    return this.fns.httpsCallable('recursiveDelete')({ path: 'progetti/' + id }).toPromise();
  }

  /**
   * Delete progetto
   */
  async createProgettoFunction(progettoData, idCliente, posizioneGruppo?) {
    if (progettoData.scadenzaInterna) { progettoData.scadenzaInterna = new Date(progettoData.scadenzaInterna).getTime() }
    if (progettoData.scadenzaUfficiale) { progettoData.scadenzaUfficiale = new Date(progettoData.scadenzaUfficiale).getTime() }
    if (progettoData.inizioSorveglianza) { progettoData.inizioSorveglianza = new Date(progettoData.inizioSorveglianza).getTime() }
    if (progettoData.fineSorveglianza) { progettoData.fineSorveglianza = new Date(progettoData.fineSorveglianza).getTime() }

    return await this.fns.httpsCallable('createProgetto')({
      progettoData: progettoData,
      idCliente: idCliente,
      studio: this.studioService.studio.id,
      posizioneGruppo: posizioneGruppo
    }).toPromise();
  }

  //FASI

  /**
   * Get all fasi
   */
  async getAllFasi(idProgetto) {

    let fasiArray: any[] = [];

    const progetto = await this.db.collection("progetti").doc(idProgetto).get().toPromise();

    const gruppi = await progetto.ref.collection("gruppi").get();

    gruppi.forEach(async (gruppo) => {

      const fasi = await gruppo.ref.collection("fasi").get()

      fasi.forEach(fase => {
        let f = {
          id: fase.id,
          nome: fase.data().nome
        };
        fasiArray.push(f);
      })

    })

    return fasiArray;
  }

  /**
   * Get all fasi by user
   *
   * TODO - Da modificare in modo che funzioni per tutti gli utenti, non solo per chi logga
   */
  async getAllFasiByUser(idProgetto: string) {
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    let fasiArray: any[] = [];

    const progetto = await this.db.collection("progetti").doc(idProgetto).get().toPromise();

    const gruppi = await progetto.ref.collection("gruppi").get();

    gruppi.forEach(async (gruppo) => {

      console.log(gruppo);

      const user = {
        id: loggedUser.id,
        nome: loggedUser.nome,
        cognome: loggedUser.cognome
      }

      console.log(user);

      //const fasi = await gruppo.ref.collection("fasi").where('users', 'array-contains', user).get()
      const fasi = await gruppo.ref.collection("fasi").get()

      fasi.forEach(fase => {

        console.log(fase);

        let f = {
          id: fase.id,
          descrizione: fase.data().descrizione,
          idGruppo: gruppo.id
        };

        fasiArray.push(f);
      })

    })

    return fasiArray;
  }

  /**
   * Get fasi
   */
  getFasi(progetto: Progetto, gruppo) {
    return this.db.collection('progetti/' + progetto.id + '/gruppi/' + gruppo.id + '/fasi').valueChanges({ idField: 'id' });
  }

  /**
   * Get fasi by gruppo
   */
  getFasiByGruppo(idProgetto: string, idGruppo: string) {
    return this.db.collection('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi', ref => ref.orderBy('posizione')).valueChanges({ idField: 'id' });
  }

  /**
   * Get fase
   */
  getFase(idProgetto: string, idGruppo: string, idFase: string): Promise<any> {
    return new Promise((resolve, reject) => {

      this.db.doc<any>('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi/' + idFase).valueChanges()
        .subscribe(selezione => {
          if (selezione) {
            this.fase = selezione;
            this.fase.id = idFase;

            if (selezione.dataApertura) this.fase.dataApertura = new Date(selezione.dataApertura?.seconds * 1000);
            if (selezione.scadenzaInterna) this.fase.scadenzaInterna = new Date(selezione.scadenzaInterna?.seconds * 1000);
            if (selezione.scadenzaUfficiale) this.fase.scadenzaUfficiale = new Date(selezione.scadenzaUfficiale?.seconds * 1000);


            /*if (selezione.preventivo.invioData) this.fase.preventivo.invioData = new Date(selezione.preventivo.invioData?.seconds * 1000);
            if (selezione.preventivo.accettazioneData) this.fase.preventivo.accettazioneData = new Date(selezione.preventivo.accettazioneData?.seconds * 1000);
            if (selezione.preventivo.solleciti.length) this.fase.preventivo.solleciti.forEach(element => element.data = new Date(element.data?.seconds * 1000));*/

          }

          this.onFaseChanged.next(this.fase);
          resolve(selezione);
        }, reject);
    });

  }

  /**
   * Update fase
   */
  updateFase(idProgetto: string, idGruppo: string, fase: any): Promise<any> {
    let id = fase.id;
    delete fase.id;

    return this.db.doc('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi/' + id).update(fase);
  }

  updateOnorarioFase(idProgetto: string, idGruppo: string, idFase: any, onorario: number): Promise<any> {
    return this.db.doc('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi/' + idFase).update({ onorario: onorario });
  }


  addNewPerson(idProgetto, idGruppo, idFase, form) {
    const u = {
      id: form.persona.id,
      nome: form.persona.nome,
      cognome: form.persona.cognome,
      attivita: form.attivita,
      durata: Number(form.durata)
    }

    var faseRef = this.db.doc('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi/' + idFase).ref;

    return faseRef.update({
      users: arrayUnion(u)
    });
  }

  removePerson(idProgetto, idGruppo, idFase, user) {
    var faseRef = this.db.doc('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi/' + idFase).ref;

    return faseRef.update({
      users: arrayRemove(user)
    });
  }

  saveCostoOrario(idProgetto, costi) {
    const faseRef = this.db.doc('progetti/' + idProgetto).ref;
    return faseRef.update({ costiOrari: costi });
  }

  openFaseFunction(progetto: any, gruppo: any, fase: any, scadenze) {
    return this.fns.httpsCallable('openFase')({
      progetto: pick(progetto, ['id', 'nome', 'proprieta', 'costoOrario']),
      idGruppo: gruppo.id,
      fase: fase,
      scadenze: scadenze,
      studio: this.studioService.studio.id
    });
  }

  deleteFaseFunction(idProgetto: string, idGruppo: string, fase: any) {
    return this.fns.httpsCallable('deleteFase')({
      idProgetto: idProgetto,
      idGruppo: idGruppo,
      fase: fase
    });
  }

  /**
   * Per concludere la fase vado a modificare lo stato in 50 ed inserire la data di chiusura.
   * Modifico anche lo stato del progetto mettendolo in attesa.
   */
  concludiFase(idProgetto: string, idGruppo: string, fase: any) {
    const batch = this.db.firestore.batch();

    const faseRef = this.db.firestore.collection('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi').doc(fase.id);
    batch.update(faseRef, { stato: 50, dataChiusura: new Date() });

    const progettoRef = this.db.firestore.collection('progetti').doc(idProgetto);
    batch.update(progettoRef, { stato: 20 });

    return batch.commit();
  }

  /**
   * Salvataggio del preventivo a livello di fase
   */
  savePreventivoFase(idProgetto, idGruppo, idFase, preventivo) {
    if (preventivo.invioData) { preventivo.invioData = new Date(preventivo.invioData); }
    if (preventivo.accettazioneData) { preventivo.accettazioneData = new Date(preventivo.accettazioneData); }

    preventivo.solleciti.forEach(element => {
      if (element.data) { element.data = new Date(element.data); }
    });

    return this.db.collection('progetti/' + idProgetto + '/gruppi/' + idGruppo + '/fasi').doc(idFase).update({ preventivo: preventivo })
  }

  //GRUPPI DI FASI

  /**
   * Get gruppi
   */
  getGruppi(progetto: Progetto) {
    return this.db.collection('progetti/' + progetto.id + '/gruppi').valueChanges({ idField: 'id' });
  }

  /**
   * Get fase
   */
  getGruppo(idProgetto: string, idGruppo: string): Promise<any> {
    return new Promise((resolve, reject) => {

      this.db.doc<any>('progetti/' + idProgetto + '/gruppi/' + idGruppo).valueChanges()
        .subscribe(selezione => {
          if (selezione) {
            this.gruppo = selezione;
            this.gruppo.id = idGruppo;
          }

          this.onGruppoChanged.next(this.gruppo);
          resolve(selezione);
        }, reject);
    });

  }

  updateGruppo(idProgetto: string, gruppo: any): Promise<any> {
    const id = gruppo.id;
    delete gruppo.id;

    return this.db.doc('progetti/' + idProgetto + '/gruppi/' + id).update(gruppo);
  }


  addFaseFunction(progetto, data, posizioneF, posizioneG) {
    return this.fns.httpsCallable('addFase')({
      progetto: progetto,
      data: data,
      posizioneF: posizioneF,
      posizioneG: posizioneG,
    })
  }

  addGruppoFunction(progetto, data, posizioneG) {
    return this.fns.httpsCallable('addGruppo')({
      progetto: progetto,
      data: data,
      posizioneG: posizioneG,
    })
  }

  //SOTTOFASI

  /**
   * Get sottofasi
   */
  getSottofasi(progetto: Progetto, gruppo, fase) {
    return this.db.collection('progetti/' + progetto.id + '/gruppi/' + gruppo.id + '/fasi/' + fase.id + '/sottofasi', ref => ref.orderBy('posizione')).valueChanges({ idField: 'id' });
  }

  /**
   * Add sottofase
   */
  addSottofase(progetto: Progetto, gruppo, fase, sottofase) {
    delete sottofase.id;

    sottofase.stato = 1; //Prima bozza
    if (fase.users) { sottofase.members = fase.users; }

    //aggiorno stato % avanzamento fase
    this.fns.httpsCallable('aggiornaPercFase')({
      pid: progetto.id,
      gid: gruppo.id,
      fid: fase.id
    }).toPromise();

    return this.db.collection('progetti/' + progetto.id + '/gruppi/' + gruppo.id + '/fasi/' + fase.id + '/sottofasi').add({ ...sottofase });
  }

  convertObjectToArray(obj) {
    const id: string[] = Object.keys(obj);
    obj = Object.values(obj);

    for (let index = 0; index < obj.length; index++) {
      obj[index].id = id[index];
    }
    return obj;
  }

  getStatiFase() {
    return this.db.collection('stati-fase', ref => ref.where('manuale', '==', true)).valueChanges({ idField: 'id' });
  }

  getUsers() {
    return this.db.collection('users').valueChanges({ idField: 'id' });
  }

  getAttivitaPersonale() {
    return this.db.collection('attivita-personale', ref => ref.orderBy('lingua')).valueChanges({ idField: 'id' });
  }

  //Bruno: da modificare per salvataggio di file generici su Progetto e Gruppo
  addPath(pid: string, gid: string, id: string, name: string, filePath: string, idStorage: string) {
    let batch = this.db.firestore.batch();

    const storageRef = this.db.collection('storage').doc(idStorage).ref
    //Bruno: commentato inserimento file sulla fase verificare se non serve da nessuna parte
    /* const faseRef = this.db.collection('progetti/' + pid + '/gruppi/' + gid + '/fasi').doc(id).ref;
    const file = {
      id: idStorage,
      nome: name
    } */

    batch.update(storageRef, {
      id: arrayUnion(id),
      idProgetto: arrayUnion(pid),
      idGruppo: arrayUnion(gid)
    });

    /* batch.update(faseRef, {
      files: firebase.firestore.FieldValue.arrayUnion(file)
    }) */

    return batch.commit();
  }

  // Temporaneo

  getDocumenti(id: string) {
    return this.db.collection('storage', ref => ref.where('id', '==', id)).valueChanges({ idField: 'id' });
  }

  removePath(idFile: string, idFase: string, field: string) {
    return this.db.collection('storage').doc(idFile).update({
      [field]: arrayRemove(idFase)
    });
  }

  getTipologieProgetto() {
    return this.db.collection('tipologie-progetto/').valueChanges();
  }

  getTipoNaturaProgetto() {
    return this.db.collection('studio').doc(this.studioService.studio.id).valueChanges();
  }

  getSottoTipoProgetto(tipo) {
    return this.db.collection('sottotipo-progetto', ref => ref.where('tipo', '==', tipo)).valueChanges();
  }

  getStatiProgetto() {
    return this.db.collection('stati-progetto').valueChanges({ idField: 'id' });
  }

  removeTag(idProgetto: string, tag: any) {
    var batch = this.db.firestore.batch();
    var docRef = this.db.collection("progetti").doc(idProgetto).ref;

    batch.update(docRef, { tags: arrayRemove(tag) });

    return batch.commit();
  }



  getOggettiSB(natura, sottotipologia) {
    switch (natura) {
      case 'marchi':
        return this.db.collection('marchi/').valueChanges({ idField: 'id' });
        break;
      case 'brevetti':
        return this.db.collection('brevetti/').valueChanges({ idField: 'id' });
        break;
      case 'design':
        return this.db.collection('design').valueChanges({ idField: 'id' });
        break;
      case 'legal':
        return this.db.collection('legal').valueChanges({ idField: 'id' });
        break;
    }
  }

  getOggettoSB(natura, id) {
    switch (natura) {
      case 'Marchio':
        return this.db.collection('marchi/').doc(id).valueChanges();
        break;
      case 'Brevetto':
        return this.db.collection('brevetti/').doc(id).valueChanges();
        break;
      case 'Design':
        return this.db.collection('design/').doc(id).valueChanges();
        break;
      case 'Legal':
        return this.db.collection('legal/').doc(id).valueChanges();
        break;
    }
  }

}


@Injectable({
  providedIn: 'root'
})
export class GruppoResolve implements Resolve<any> {

  constructor(private _progettoService: ProgettoService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this._progettoService.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this._progettoService.getProgetto(route.paramMap.get('pid')),
        this._progettoService.getGruppo(route.paramMap.get('pid'), route.paramMap.get('gid'))
      ]).then(() => {
        resolve();
      },
        reject
      );
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class FaseResolve implements Resolve<any> {

  constructor(private _progettoService: ProgettoService, private spesaService: SpesaService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this._progettoService.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this._progettoService.getProgetto(route.paramMap.get('pid')),
        this._progettoService.getGruppo(route.paramMap.get('pid'), route.paramMap.get('gid')),
        this._progettoService.getFase(route.paramMap.get('pid'), route.paramMap.get('gid'), route.paramMap.get('fid')),
        this.spesaService.getSpesa('new'),

      ]).then(() => {
        resolve();
      },
        reject
      );
    });
  }
}
