import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendaService implements Resolve<any>
{
  events: any;
  onEventsUpdated: Subject<any>;

  /**
   * Constructor
   */
  constructor(
    private db: AngularFirestore,
    private fns: AngularFireFunctions
  ) {
    // Set the defaults
    this.onEventsUpdated = new Subject();

    this.db.firestore.settings({
      ignoreUndefinedProperties: true,
    });

    console.log("Calendar service");
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getEvents()
      ]).then(
        ([events]: [any]) => {
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get events
   *
   * @returns {Promise<any>}
   */
  async getEvents(): Promise<any> {
    console.log("Get events");
    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    return new Promise((resolve, reject) => {
      this.db.collection('agenda', ref => ref.where('user.id', '==', loggedUser.id)).valueChanges({ idField: 'id' })
        .subscribe({
          next: (eventi) => {

            this.events = eventi;

            this.events.forEach(element => {
              if (element.start) {
                element.start = new Date(element.start?.seconds * 1000);
              }
              if (element.end) {
                element.end = new Date(element.end?.seconds * 1000);
              }
            });

            this.onEventsUpdated.next(this.events);
            //resolve(result)
            resolve(eventi)
          },
          error: (error) => {
            console.error(error);
            reject;
          }
        });

    });
  }

  /**
   * Get events
   *
   * @returns {Promise<any>}
   */
  async getEventsByUser(userId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection('agenda', ref => ref.where('user.id', '==', userId)).valueChanges({ idField: 'id' })
        .subscribe(result => {
          this.events = result;

          this.events.forEach(element => {
            element.dataScadenza = new Date(element.dataScadenza?.seconds * 1000);
          });


          this.events.forEach(element => {
            if (element.start) {
              element.start = new Date(element.start?.seconds * 1000);

            }
            if (element.end) {
              element.end = new Date(element.end?.seconds * 1000);
            }
          });

          this.onEventsUpdated.next(this.events);
          resolve(result)
        }, reject);

    });
  }


  /**
   * vediScadenzario
   *
   * @returns {Promise<any>}
   */
  async vediScadenzario(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection('agenda', ref => ref.where('scadenzario', '==', true)).valueChanges({ idField: 'id' })
        .subscribe(result => {
          this.events = result;

          this.events.forEach(element => {
            element.dataScadenza = new Date(element.dataScadenza?.seconds * 1000);
          });


          this.events.forEach(element => {
            if (element.start) {
              element.start = new Date(element.start?.seconds * 1000);

            }
            if (element.end) {
              element.end = new Date(element.end?.seconds * 1000);
            }
          });

          this.onEventsUpdated.next(this.events);
          resolve(result)
        }, reject);

    });
  }

  /**
   * Update an Event
   *
   * @param events
   */
  updateEvents(event) {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    const id = event.id;
    const startHour = event.startHour;
    const startMinutes = event.startMinutes;
    const endHour = event.endHour;
    const endMinutes = event.endMinutes;

    delete event.id;
    delete event.startHour;
    delete event.startMinutes;
    delete event.endHour;
    delete event.endMinutes;

    if (event.start) { event.start = new Date(event.start); event.start.setHours(startHour); event.start.setMinutes(startMinutes) }
    if (event.end) { event.end = new Date(event.end); event.end.setHours(endHour); event.end.setMinutes(endMinutes) }

    event.durata = Math.abs(event.start - event.end) / 36e5;

    const agendaRef = this.db.firestore.collection('agenda').doc(id);
    const faseRef = this.db.firestore.collection('progetti').doc(event.progetto.id).collection('gruppi').doc(event.fase.idGruppo).collection('fasi').doc(event.fase.id);

    return this.db.firestore.runTransaction((transaction) => {
      return transaction.get(faseRef).then((faseRes) => {
        var oldTempoCompilato = 0;
        if (faseRes.exists) {
          oldTempoCompilato = faseRes.data().tempoCompilato;
        }

        return transaction.get(agendaRef).then((agendaRes) => {
          if (!agendaRes.exists) {
            throw "Document agenda does not exist!";
          }

          let oldDurata = agendaRes.data().durata;
          var newTempoCompilato = 0;

          if (oldTempoCompilato > 0)
            newTempoCompilato = oldTempoCompilato - oldDurata + event.durata;

          if (faseRes.exists) {
            transaction.update(faseRef, {
              tempoCompilato: newTempoCompilato
            });
          }
          transaction.update(agendaRef, event);
        })

      });
    });
  }

  /**
   * Delete an Event
   *
   * @param event
   */
  deleteEvent(event) {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    const agendaRef = this.db.firestore.collection('agenda').doc(event.id);
    const faseRef = this.db.firestore.collection('progetti').doc(event.progetto.id).collection('gruppi').doc(event.fase.idGruppo).collection('fasi').doc(event.fase.id);
    const timesheetRef = this.db.firestore.collection('timesheet').doc(event.id);

    return this.db.firestore.runTransaction((transaction) => {
      return transaction.get(faseRef).then((faseRes) => {
        var oldTempoCompilato = 0;
        if (faseRes.exists) {
          oldTempoCompilato = faseRes.data().tempoCompilato;
        }

        return transaction.get(agendaRef).then((agendaRes) => {
          if (!agendaRes.exists) {
            throw "Document does not exist!";
          }

          //Rimuovo la durata dal tempo compilato
          let oldDurata = agendaRes.data().durata;

          var newTempoCompilato = 0;
          if (oldTempoCompilato > 0)
            newTempoCompilato = oldTempoCompilato - oldDurata;

          //Update tempo compilato
          if (faseRes.exists) {
            transaction.update(faseRef, {
              tempoCompilato: newTempoCompilato
            });
          }

          //Eliminazione evento
          transaction.delete(agendaRef);

          //Eliminazione dal timesheet
          transaction.delete(timesheetRef);
        })
      });
    });
  }


  /**
   * Add an Event
   *
   * @param event
   */
  addEvents(event, idNotifica?) {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

    delete event.id;

    const startHour = event.startHour;
    const startMinutes = event.startMinutes;
    const endHour = event.endHour;
    const endMinutes = event.endMinutes;

    delete event.startHour;
    delete event.startMinutes;
    delete event.endHour;
    delete event.endMinutes;

    if (event.start) { event.start = new Date(event.start); event.start.setHours(startHour); event.start.setMinutes(startMinutes) }
    if (event.end) { event.end = new Date(event.end); event.end.setHours(endHour); event.end.setMinutes(endMinutes) }

    event.durata = Math.abs(event.start - event.end) / 36e5;

    //const agendaRef = this.db.firestore.collection('users').doc(loggedUser.id).collection('agenda').doc();  vecchia gestione su utente
    const agendaRef = this.db.firestore.collection('agenda').doc();
    const faseRef = this.db.firestore.collection('progetti').doc(event.progetto?.id).collection('gruppi').doc(event.fase.idGruppo).collection('fasi').doc(event.fase?.id);

    let notificaRef;
    if (idNotifica) notificaRef = this.db.firestore.collection('todo').doc(idNotifica);

    return this.db.firestore.runTransaction((transaction) => {
      return transaction.get(faseRef).then((res) => {
        if (res.exists) {
          var oldTempoCompilato = res.data().tempoCompilato;
          var newTempoCompilato = oldTempoCompilato + event.durata;

          transaction.update(faseRef, {
            tempoCompilato: newTempoCompilato
          });
        }

        transaction.set(agendaRef, event);

        if (idNotifica) {
          transaction.update(notificaRef, {
            stato: 1,
            important: false //Bruno: tolgo l'importante dopo la presa in carico
          });
        }

      });
    });
  }

  updateTimesheet() {
    return this.fns.httpsCallable('updateTimesheet')({}).toPromise();
  }

  lockTimesheet() {
    return this.fns.httpsCallable('lockTimesheet')({}).toPromise();
  }
}
