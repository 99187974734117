import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/authentication/auth.service';
import { Studio } from 'app/main/impostazioni/studio/model/studio.model';
import { SelezioneStudioComponent } from 'app/main/impostazioni/studio/selezione-studio/selezione-studio.component';
import { StudioService } from 'app/main/impostazioni/studio/studio.service';
import { UsersService } from 'app/main/impostazioni/users/users.service';
import { TodoService } from 'app/main/todo/todo.service';
import { navigation } from 'app/navigation/navigation';
import { LANGUAGES } from 'app/shared/app.constants';
import { SnackbarService } from 'app/shared/service/snackbar.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from '../i18n/en';
import { locale as italiano } from '../i18n/it';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];

  notifiche: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   * @param {AuthService} authService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private snackbarSerivce: SnackbarService,
    public authService: AuthService,
    public usersService: UsersService,
    public studioService: StudioService,
    public dialog: MatDialog,
    private router: Router,
    private _todoService: TodoService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(italiano, english);

    this.languages = LANGUAGES;

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });

    this.notifiche = false;

    this._todoService.getAsyncNotifsCount()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(valore => this.notifiche = valore > 0);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout() {
    this.authService.logout();
  }

  selezioneStudio() {
    const confirmDialog = this.dialog.open(SelezioneStudioComponent, {
      data: {
        title: 'Selezione studio',
        message: 'Seleziona lo studio',
        annulla: true,
      },
      disableClose: true,
      width: '400px',
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        localStorage.setItem('studio', JSON.stringify(result));
        this.studioService.studio = new Studio(result);
        this.router.navigate(['home']);
        this.snackbarSerivce.open("Studio modificato correttamente.", "top", 5000, "snackbar-style-succes");
      }
      else {
        if (result != "") {
          this.snackbarSerivce.open("Errore durante la modifica dello studio.", "top", 5000, "snackbar-style-error");
        }
      }
    });
  }
}
