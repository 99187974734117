import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Studio } from './model/studio.model';

@Injectable({
  providedIn: 'root'
})
export class StudioService {

  routeParams: any;
  studio: Studio = new Studio();
  onStudioChanged: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private db: AngularFirestore) {
    this.onStudioChanged = new BehaviorSubject({});

    if (localStorage.getItem('studio')) {
      this.studio = new Studio(JSON.parse(localStorage.getItem('studio')));
      this.onStudioChanged.next(this.studio);
    }
  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getStudio(this.routeParams.id)
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  getStudio(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.routeParams.id === 'new') {
        console.log("new");
        this.onStudioChanged.next(false);
        resolve(false);
      }
      else {
        this.db.doc<any>('studio/' + id).valueChanges()
          .subscribe(selezione => {
            if (selezione) {
              this.studio = selezione;
              this.studio.id = id; //metto l'id passato
            }
            this.onStudioChanged.next(selezione);
            resolve(selezione);
          }, reject);
      }
    });
  }

  getStudi() {
    return this.db.collection('studio').valueChanges({ idField: 'id' });
  }

  updateStudio(studio) {
    let id = this.routeParams.id;
    delete studio.id; //cancello perchè l'id di firestore è un metadata e non un campo db
    return this.db.doc('studio/' + id).update(studio)
      .then(() => {
        studio.id = id;
      });
  }

  getTipoNaturaProgetto() {
    return this.db.collection('studio').doc(this.studio.id).valueChanges();
  }
}
