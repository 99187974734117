import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'fuse-theme-options',
  templateUrl: './theme-options.component.html',
  styleUrls: ['./theme-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FuseThemeOptionsComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  form: UntypedFormGroup;

  @HostBinding('class.bar-closed')
  barClosed: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FormBuilder} _formBuilder
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Renderer2} _renderer
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _formBuilder: UntypedFormBuilder,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _renderer: Renderer2
  ) {
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Build the config form
    // noinspection TypeScriptValidateTypes
    this.form = this._formBuilder.group({
      colorTheme: new UntypedFormControl(),
      customScrollbars: new UntypedFormControl(),
      layout: this._formBuilder.group({
        style: new UntypedFormControl(),
        width: new UntypedFormControl(),
        navbar: this._formBuilder.group({
          primaryBackground: new UntypedFormControl(),
          secondaryBackground: new UntypedFormControl(),
          folded: new UntypedFormControl(),
          hidden: new UntypedFormControl(),
          position: new UntypedFormControl(),
          variant: new UntypedFormControl()
        }),
        toolbar: this._formBuilder.group({
          background: new UntypedFormControl(),
          customBackgroundColor: new UntypedFormControl(),
          hidden: new UntypedFormControl(),
          position: new UntypedFormControl()
        }),
        footer: this._formBuilder.group({
          background: new UntypedFormControl(),
          customBackgroundColor: new UntypedFormControl(),
          hidden: new UntypedFormControl(),
          position: new UntypedFormControl()
        }),
        sidepanel: this._formBuilder.group({
          hidden: new UntypedFormControl(),
          position: new UntypedFormControl()
        })
      })
    });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        // Update the stored config
        this.fuseConfig = config;

        // Set the config form values without emitting an event
        // so that we don't end up with an infinite loop
        this.form.setValue(config, { emitEvent: false });
      });

    // Subscribe to the specific form value changes (layout.style)
    this.form.get('layout.style').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {

        // Reset the form values based on the
        // selected layout style
        this._resetFormValues(value);
      });

    // Subscribe to the form value changes
    this.form.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        // Update the config
        this._fuseConfigService.config = config;
      });

    // Add customize nav item that opens the bar programmatically
    const customFunctionNavItem = {
      id: 'custom-function',
      title: 'Custom Function',
      type: 'group',
      icon: 'settings',
      children: [
        {
          id: 'customize',
          title: 'Customize',
          type: 'item',
          icon: 'settings',
          function: () => {
            this.toggleSidebarOpen('themeOptionsPanel');
          }
        }
      ]
    };

    this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(false);
    this._unsubscribeAll.complete();

    // Remove the custom function menu
    this._fuseNavigationService.removeNavigationItem('custom-function');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Reset the form values based on the
   * selected layout style
   *
   * @param value
   * @private
   */
  private _resetFormValues(value): void {
    switch (value) {
      // Vertical Layout #1
      case 'vertical-layout-1':
        {
          this.form.patchValue({
            layout: {
              width: 'fullwidth',
              navbar: {
                primaryBackground: 'fuse-navy-700',
                secondaryBackground: 'fuse-navy-900',
                folded: false,
                hidden: false,
                position: 'left',
                variant: 'vertical-style-1'
              },
              toolbar: {
                background: 'fuse-white-500',
                customBackgroundColor: false,
                hidden: false,
                position: 'below-static'
              },
              footer: {
                background: 'fuse-navy-900',
                customBackgroundColor: true,
                hidden: false,
                position: 'below-static'
              },
              sidepanel: {
                hidden: false,
                position: 'right'
              }
            }
          });

          break;
        }

      // Vertical Layout #2
      case 'vertical-layout-2':
        {
          this.form.patchValue({
            layout: {
              width: 'fullwidth',
              navbar: {
                primaryBackground: 'fuse-navy-700',
                secondaryBackground: 'fuse-navy-900',
                folded: false,
                hidden: false,
                position: 'left',
                variant: 'vertical-style-1'
              },
              toolbar: {
                background: 'fuse-white-500',
                customBackgroundColor: false,
                hidden: false,
                position: 'below'
              },
              footer: {
                background: 'fuse-navy-900',
                customBackgroundColor: true,
                hidden: false,
                position: 'below'
              },
              sidepanel: {
                hidden: false,
                position: 'right'
              }
            }
          });

          break;
        }

      // Vertical Layout #3
      case 'vertical-layout-3':
        {
          this.form.patchValue({
            layout: {
              width: 'fullwidth',
              navbar: {
                primaryBackground: 'fuse-navy-700',
                secondaryBackground: 'fuse-navy-900',
                folded: false,
                hidden: false,
                position: 'left',
                layout: 'vertical-style-1'
              },
              toolbar: {
                background: 'fuse-white-500',
                customBackgroundColor: false,
                hidden: false,
                position: 'above-static'
              },
              footer: {
                background: 'fuse-navy-900',
                customBackgroundColor: true,
                hidden: false,
                position: 'above-static'
              },
              sidepanel: {
                hidden: false,
                position: 'right'
              }
            }
          });

          break;
        }

      // Horizontal Layout #1
      case 'horizontal-layout-1':
        {
          this.form.patchValue({
            layout: {
              width: 'fullwidth',
              navbar: {
                primaryBackground: 'fuse-navy-700',
                secondaryBackground: 'fuse-navy-900',
                folded: false,
                hidden: false,
                position: 'top',
                variant: 'vertical-style-1'
              },
              toolbar: {
                background: 'fuse-white-500',
                customBackgroundColor: false,
                hidden: false,
                position: 'above'
              },
              footer: {
                background: 'fuse-navy-900',
                customBackgroundColor: true,
                hidden: false,
                position: 'above-fixed'
              },
              sidepanel: {
                hidden: false,
                position: 'right'
              }
            }
          });

          break;
        }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
