import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FuseMatchMediaService {
  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Constructor
   *
   * @param {MediaObserver} _mediaObserver
   */
  constructor(
    private _mediaObserver: MediaObserver
  ) {
    // Set the defaults
    this.activeMediaQuery = '';

    // Initialize
    this._init();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    this._mediaObserver.asObservable()
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe((change) => {

        change.forEach((item) => {
          if (this.activeMediaQuery !== item.mqAlias) {
            this.activeMediaQuery = item.mqAlias;
            this.onMediaChange.next(item.mqAlias);
          }
        });



      });
  }

}
