export const locale = {
  lang: 'it',
  data: {
    'sottotipologia': 'Sottotipologia',
    'progetto': 'Progetto',
    'fase': 'Fase',
    'data': 'Data',
    'startHour': 'H inizio',
    'startMinutes': 'M inizio',
    'endHour': 'H fine',
    'endMinutes': 'M fine',
    'note': 'Note'
  }
};
