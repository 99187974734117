import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { LoadAuthGuard } from './guard/auth-load.guard';
import { AgendaComponent } from './main/agenda/agenda.component';
import { AgendaService } from './main/agenda/agenda.service';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

//Da aggiungere i permessi giusti in base al Foglio Ruoli finito
const appRoutes: Routes = [
  {
    path: 'auth',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome },
    loadChildren: () => import('./authentication/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'sbarea',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/sbarea/sbarea.module').then(m => m.SbareaModule)
  },
  {
    path: 'aziende',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/aziende/aziende.module').then(m => m.AziendeModule)
  },
  {
    path: 'progetti',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/progetti/progetti.module').then(m => m.ProgettiModule)
  },
  {
    path: 'rubrica',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/rubrica/rubrica.module').then(m => m.RubricaModule)
  },
  {
    path: 'todo',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/todo/todo.module').then(m => m.TodoModule)
  },
  {
    path: 'spese',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/spese/spese.module').then(m => m.SpeseModule)
  },
  {
    path: 'contabilita',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/contabilita/contabilita.module').then(m => m.ContabilitaModule)
  },
  {
    path: 'impostazioni',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/impostazioni/impostazioni.module').then(m => m.ImpostazioniModule)
  },
  {
    path: 'documentale',
    canLoad: [LoadAuthGuard],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./main/documentale/documentale.module').then(m => m.DocumentaleModule)
  },
  // Caricata all'inizio, perchè il AgendaComponent è utilizzato in più parti
  // TODO - Creare uno shared module che esporta i moduli comuni
  {
    path: 'agenda',
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
    component: AgendaComponent,
    resolve: { data: AgendaService }
  },
  {
    path: '**',
    redirectTo: 'home'
  },
  // TODO DOPO QUESTA RIGA
  // ----------------------------------------------------------------------------------------------------------------------
  //{ path: 'documentale', component: FileManagerComponent, canActivate: [AuthGuard, ClaimGuard], data: { roles: ['studioAdmin', 'studioAdminSegreteria'] }, resolve: { data: FileManagerService } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}