import { NgModule } from '@angular/core';
import { GetCurrencySymbolPipe } from './getCurrencySymbol.pipe';
import { GetDownloadUrlPipe } from './getDownloadUrl.pipe';
import { InArrayPipe } from './inArray.pipe';
import { MapNazioniPipe } from './mapNazioni.pipe';
import { ObjectPropertyPipe } from './objectProperty.pipe';

@NgModule({
  declarations: [
    InArrayPipe,
    ObjectPropertyPipe,
    GetDownloadUrlPipe,
    GetCurrencySymbolPipe,
    MapNazioniPipe
  ],
  imports: [],
  exports: [
    InArrayPipe,
    ObjectPropertyPipe,
    GetDownloadUrlPipe,
    GetCurrencySymbolPipe,
    MapNazioniPipe
  ]
})
export class PipesModule {
}
