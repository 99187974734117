import { CalendarEventAction } from 'angular-calendar';
import { endOfDay, startOfDay } from 'date-fns';

export class CalendarEventModel {
  id: string;
  start: Date;
  end: Date;
  title: string;
  actions?: CalendarEventAction[];
  allDay?: boolean;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  meta?: {
    notes: string
  };

  // Dati da aggiungere qui (idUser, idProprieta, idProgetto, idFase)

  natura: string;
  idSBArea: string;
  sottotipologia: string;
  progetto: any;
  fase: any;
  durata: number;
  stato: boolean;
  scadenzario: boolean;
  nominativo: string;
  user: string;
  /**
   * Constructor
   *
   * @param data
   */
  constructor(data?) {
    data = data || {};
    this.id = data.id || '';
    this.start = new Date(data.start) || startOfDay(new Date());
    this.end = new Date(data.end) || endOfDay(new Date());
    this.title = data.title || '';
    this.resizable = {
      beforeStart: data.resizable && data.resizable.beforeStart || false,
      afterEnd: data.resizable && data.resizable.afterEnd || false
    };
    this.actions = data.actions || [];
    this.allDay = data.allDay || false;
    this.meta = {
      notes: data.meta && data.meta.notes || ''
    };
    this.natura = data.natura || '';
    this.idSBArea = data.idSBArea || '';
    this.sottotipologia = data.sottotipologia || '';
    this.progetto = data.progetto || '';
    this.fase = data.fase || '';
    this.durata = data.durata || 0;
    this.stato = data.stato ? true : false;
    this.scadenzario = data.scadenzario ? true : false;
    this.nominativo = data.nominativo || '';
    this.user = data.user || '';
  }
}
