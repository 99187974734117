import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseUtils } from '@fuse/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AgendaService } from '../agenda/agenda.service';
import { Notifica } from './notifica.model';
import { Todo } from './todo.model';


@Injectable({
  providedIn: 'root'
})
export class TodoService implements Resolve<any>
{

  selectedTodos: Todo[];
  currentTodo: Notifica;

  filters: any[];
  users: any[];
  tags: any[];
  routeParams: any;


  searchText: string;
  filterTags: string[];
  filtriFissi: string[];

  // New
  notifica: Notifica;
  notifiche: Notifica[];
  notificheFiltered: Notifica[];

  onNotificaChanged: BehaviorSubject<any>;
  onNotificheFilteredChanged: BehaviorSubject<any>;
  onNewNotificaClicked: Subject<any>;

  onSearchTextChanged: BehaviorSubject<any>;
  onFilterTagsChanged: BehaviorSubject<any>;
  onFiltriFissiChanged: BehaviorSubject<any>;

  onTodosChanged: BehaviorSubject<any>;
  onCurrentTodoChanged: BehaviorSubject<any>;
  onTagsChanged: BehaviorSubject<any>;
  onUsersChanged: BehaviorSubject<any>;

  onNewTodoClicked: Subject<any>;

  notifsCountSubject = new BehaviorSubject<any>(0);
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param {Location} _location
   */
  constructor(
    private db: AngularFirestore,
    private calendarService: AgendaService
  ) {
    this.onNotificaChanged = new BehaviorSubject([]);
    this.onNotificheFilteredChanged = new BehaviorSubject([]);
    this.onNewNotificaClicked = new Subject();

    // Set the defaults
    this.selectedTodos = [];
    this.searchText = '';
    this.filterTags = [];
    this.filtriFissi = [];
    this.onTodosChanged = new BehaviorSubject([]);
    this.onCurrentTodoChanged = new BehaviorSubject([]);
    this.onTagsChanged = new BehaviorSubject([]);
    this.onUsersChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new BehaviorSubject('');
    this.onFilterTagsChanged = new BehaviorSubject([]);
    this.onFiltriFissiChanged = new BehaviorSubject([]);
    this.onNewTodoClicked = new Subject();
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this.getTags(),
        this.getTodos(),
        this.getStudioUsers(),
        //this.getNotifica(this.routeParams.id),
        this.calendarService.getEvents(),
      ]).then(
        () => {


          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.filterNotifiche();
          });

          this.onFilterTagsChanged.subscribe(filterTags => {
            this.filterTags = filterTags;
            this.filterNotifiche();
          });

          this.onFiltriFissiChanged.subscribe(filtriFissi => {
            this.filtriFissi = filtriFissi;
            this.filterNotifiche();
          });

          resolve();
        },
        reject
      );

    });
  }

  /**
   * Get notifica
   *
   * @returns {Promise<any>}
   */

  getNotifica(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (id != undefined && id === 'new') {
        this.onNotificaChanged.next([this.notifica, 'new']);
        resolve(false);
      }
      else {
        this.db.doc<Notifica>('todo/' + id).valueChanges()
          .subscribe(selezione => {
            if (selezione) {
              this.notifica = selezione;
              this.notifica.id = id; //metto l'id passato

              if (selezione.dataCreazione) {
                this.notifica.dataCreazione = new Date(selezione.dataCreazione?.seconds * 1000);
              }
            }

            this.onNotificaChanged.next([this.notifica, 'edit']);
            resolve(selezione);
          }, reject);
      }
    });
  }

  /**
   * Get all tags
   *
   * @returns {Promise<any>}
   */
  getTags(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection('tags').valueChanges({ idField: 'id' })
        .subscribe((response: any) => {
          this.tags = response;
          this.onTagsChanged.next(this.tags);
          resolve(this.tags);
        }, reject);
    });
  }

  /**
   * Get all users
   *
   * @returns {Promise<any>}
   */
  getStudioUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.collection('users', (ref) => ref.where('registrato', '==', true)).valueChanges({ idField: 'id' })
        .subscribe(data => {
          this.users = data;
          this.onUsersChanged.next(this.users);
          resolve(this.users);
        }, reject);
    });
  }

  /**
   * Get notifiche
   *
   * @returns {Promise<Todo[]>}
   */
  getTodos(): Promise<any[]> {
    if (this.routeParams.tagHandle) {
      return this.getTodosByTag(this.routeParams.tagHandle);
    }

    if (this.routeParams.filterHandle) {
      return this.getTodosByFilter(this.routeParams.filterHandle);
    }

    return this.getAllTodos();
  }

  getAllTodos(utente = null): Promise<any[]> {
    console.log("get notifiche");

    let loggedUser;
    if (!utente) {
      loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    }
    else {
      loggedUser = utente;
    }

    return new Promise((resolve, reject) => {
      return this.db.collection('todo', ref => (ref.where('utente.id', '==', loggedUser.id).orderBy('dataCreazione', 'desc'))).valueChanges({ idField: 'id' })
        .subscribe(data => {

          this.notifiche = data.map(todo => {
            return new Notifica(todo);
          });

          this.notifiche.forEach(todo => {
            if (todo.dataCreazione) {
              todo.dataCreazione = new Date(todo.dataCreazione?.seconds * 1000);
            }
          });

          //this.notifiche = FuseUtils.filterArrayByString(this.notifiche, this.searchText);
          this.notificheFiltered = this.notifiche.filter(option => { return option.stato != 2 });
          //this.notificheFiltered = this.notifiche;

          this.onTodosChanged.next(this.notificheFiltered);
          resolve(this.notifiche);
        }, reject);
    })

  }



  /**
   * Get notifiche by filter
   *
   * @param handle
   * @returns {Promise<Todo[]>}
   */
  getTodosByFilter(filter): Promise<any[]> {

    return new Promise((resolve, reject) => {

      return this.db.collection('todo', ref => ref.where(filter, '==', true)).valueChanges({ idField: 'id' })
        .subscribe(data => {
          this.notifiche = data.map(todo => {
            return new Notifica(todo);
          });

          this.notifiche = FuseUtils.filterArrayByString(this.notifiche, this.searchText);

          this.onTodosChanged.next(this.notifiche);

          resolve(this.notifiche);
        }, reject);
    });
  }

  /**
   * Get notifiche by tag
   *
   * @param handle
   * @returns {Promise<Todo[]>}
   */
  getTodosByTag(userId): Promise<any[]> {

    return new Promise((resolve, reject) => {
      return this.db.collection('todo', ref => ref.where('tags', 'array-contains', userId)).valueChanges({ idField: 'id' })
        .subscribe(data => {
          this.notifiche = data.map(todo => {
            return new Notifica(todo);
          });

          this.notifiche = FuseUtils.filterArrayByString(this.notifiche, this.searchText);

          this.onTodosChanged.next(this.notifiche);

          resolve(this.notifiche);
        }, reject);
    });
  }



  /**
   * Set current todo by id
   *
   * @param id
   */
  setCurrentTodo(id): void {
    this.notifica = this.notifiche.find(todo => {
      return todo.id === id;
    });

    //if (this.notifica.stato != 2) this.onNotificaChanged.next([this.notifica, 'edit']);
    //posso modificare anche la notifica "completata"
    this.onNotificaChanged.next([this.notifica, 'edit']);

    /*const tagHandle = this.routeParams.tagHandle,
      filterHandle = this.routeParams.filterHandle;

    if (tagHandle) {
      this._location.go('todo/tag/' + tagHandle + '/' + id);
    }
    else if (filterHandle) {
      this._location.go('todo/filter/' + filterHandle + '/' + id);
    }
    else {
      this._location.go('todo/' + id);
    }*/
  }

  /**
   * Toggle tag on selected notifiche
   *
   * @param tagId
   */
  toggleTagOnSelectedTodos(tagId): void {
    this.selectedTodos.map(todo => {
      this.toggleTagOnTodo(tagId, todo);
    });
  }

  /**
   * Toggle tag on todo
   *
   * @param tagId
   * @param todo
   */
  toggleTagOnTodo(tagId, todo): void {
    todo.tags.push(tagId);
  }

  /**
   * Has tag?
   *
   * @param tagId
   * @param todo
   * @returns {boolean}
   */
  hasTag(tagId, todo): any {
    if (!todo.users) {
      return false;
    }

    return todo.users.indexOf(tagId) !== -1;
  }

  /**
   * Update the todo
   *
   * @param todo
   * @returns {Promise<any>}
   */
  updateTodo(todo): Promise<any> {
    const id = todo.id;
    const user = {
      nome: todo.utente.nome,
      cognome: todo.utente.cognome,
      id: todo.utente.id
    }

    delete todo.id;
    todo.utente = user;

    if (todo.dataCreazione) { todo.dataCreazione = new Date(todo.dataCreazione); }

    return this.db.doc('todo/' + id).update({ ...todo })
      .then(() => {
        todo.id = id;
      });
  }

  /**
   * Add a new todo
   * @param todo
   * @param important (true = campanello attivo sulla notifica)
   */
  addTodo(todo, important = true): Promise<any> {
    const user = {
      nome: todo.utente.nome,
      cognome: todo.utente.cognome,
      id: todo.utente.id
    }

    delete todo.id;
    todo.utente = user;
    todo.stato = 0;
    todo.important = important;

    if (todo.dataCreazione) { todo.dataCreazione = new Date(todo.dataCreazione); }

    return this.db.collection('todo').add({ ...todo })
      .then(response => {
        todo.id = response.id;
      });
  }

  deleteTodo(todo) {
    const id = todo.id;
    return this.db.doc('todo/' + id).delete();
  }

  filterNotifiche() {
    let filterValue = this.searchText;
    const filterTags = this.filterTags;
    const filtriFissi = this.filtriFissi;

    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();

    if (filterValue != '' || filterTags.length || filtriFissi.length) {
      this.notificheFiltered = this.notifiche.filter(option => option.titolo.toLowerCase().includes(filterValue)
        && filterTags.every(tag => option.tags.includes(tag))
        && filtriFissi.every(filtro => {
          if (parseInt(filtro) == 0) return option.starred == true;
          else if (parseInt(filtro) == 1) return option.important == true;
          else if (parseInt(filtro) == 4) return option.stato == 2;
        })
      );
      this.onTodosChanged.next(this.notificheFiltered);
    }
    else {
      //Bruno: lo stato completato non lo visualizzo mai all'inizio
      this.notificheFiltered = this.notifiche.filter(option => { return option.stato != 2 });
      this.onTodosChanged.next(this.notificheFiltered);
    }
  }

  getNrNotifiche(): Promise<any> {
    console.log("get numero notifiche");

    let loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser) {
      return new Promise((resolve, reject) => {
        this.db.collection('todo', ref => (ref.where('utente.id', '==', loggedUser.id).where('important', '==', true))).valueChanges({ idField: 'id' })
          .subscribe(data => {
            this.notifsCountSubject.next(data.length);
          }, reject);
      });
    }

  }

  getAsyncNotifsCount(): Observable<any> {
    return this.notifsCountSubject.asObservable();
  }

}
