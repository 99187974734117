import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getById'
})
export class GetByIdPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} value
   * @param {string} id
   * @param {string} property
   * @returns {any}
   */
  transform(value: any[], id: string, property: string): any {

    const foundItem = value.find(item => {
      if (item.id !== undefined) {
        return item.id === id;
      }
      return false;
    });

    if (foundItem) {
      return foundItem[property];
    }
  }
}
