import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'applications',
    title: '',
    translate: '',
    icon: 'settings',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Utenti',
        translate: 'NAV.UTENTI',
        type: 'item',
        icon: 'perm_identity',
        url: '/impostazioni/users'
      },
      /*{
        id: 'documentale',
        title: 'Modelli documenti',
        translate: 'NAV.DOCUMENTALE',
        type: 'item',
        icon: 'folder',
        url: '/impostazioni/documentale'
      },*/
      {
        id: 'timesheet',
        title: 'Timesheet',
        translate: 'NAV.TIMESHEET',
        type: 'item',
        icon: 'calendar_today',
        url: '/impostazioni/timesheet'
      },
      {
        id: 'tabelle',
        title: 'Tabelle',
        translate: 'NAV.TABELLE',
        type: 'group',
        icon: 'apps',
        children: [
          {
            id: 'template',
            title: 'Template',
            translate: 'NAV.TEMPLATE',
            type: 'item',
            icon: 'check_box',
            url: '/progetti/gestione/template'
          },
          {
            id: 'valute',
            title: 'Valute',
            translate: 'NAV.VALUTE',
            type: 'item',
            icon: 'currency_exchange',
            url: '/impostazioni/valute',
          },
          {
            id: 'nazione',
            title: 'Nation',
            translate: 'NAV.NAZIONE',
            type: 'item',
            icon: 'flag',
            url: '/impostazioni/nazioni',
          },
          {
            id: 'tags',
            title: 'Tags',
            translate: 'NAV.TAGS',
            type: 'item',
            icon: 'label',
            url: '/impostazioni/tags',
          },
          /*{
            id: 'status',
            title: 'Status',
            translate: 'NAV.STATUS',
            type: 'item',
            url: '/impostazioni/status',
          },*/
          {
            id: 'sottofasi',
            title: 'Sottofasi',
            translate: 'NAV.SOTTOFASI',
            type: 'item',
            icon: 'subdirectory_arrow_right',
            url: '/impostazioni/sottofasi',
          }
        ]
      }
    ]
  },
  {
    id: 'progettiMenu',
    title: 'Progetti',
    translate: 'NAV.PROGETTI',
    icon: 'ballot',
    type: 'item',
    url: '/progetti'
  },
  {
    id: 'applications',
    title: 'SBarea',
    translate: 'NAV.SBAREA',
    icon: 'group_work',
    type: 'group',
    children: [
      {
        id: 'marchi',
        title: 'Marchi',
        translate: 'NAV.MARCHI',
        type: 'item',
        icon: 'branding_watermark',
        url: '/sbarea/marchi'
      },
      {
        id: 'brevetti',
        title: 'Brevetti',
        translate: 'NAV.BREVETTI',
        type: 'item',
        icon: 'gesture',
        url: '/sbarea/brevetti'
      },
      {
        id: 'design',
        title: 'Design',
        translate: 'NAV.DESIGN',
        type: 'item',
        icon: 'widgets',
        url: '/sbarea/design'
      }
    ]
  },
  /* {
      id: 'legal',
      title: 'Legal',
      translate: 'NAV.LEGAL',
      type: 'item',
      icon: 'ballot',
      url: '/legal'
  }, */
  {
    id: 'aziendeMenu',
    title: 'Aziende',
    translate: 'NAV.AZIENDE',
    icon: 'group_work',
    type: 'group',
    children: [
      {
        id: 'clienti',
        title: 'Clienti',
        translate: 'NAV.CLIENTI',
        type: 'item',
        icon: 'business',
        url: '/aziende/clienti'
      },
      {
        id: 'fornitori',
        title: 'Fornitori',
        translate: 'NAV.FORNITORI',
        type: 'item',
        icon: 'work',
        url: '/aziende/fornitori'
      },
      {
        id: 'corrispondenti',
        title: 'Corrispondenti',
        translate: 'NAV.CORRISPONDENTI',
        type: 'item',
        icon: 'person',
        url: '/aziende/corrispondenti'
      }
    ]
  },
  /* {
    id: 'rubrica',
    title: 'Rubrica',
    translate: 'NAV.RUBRICA',
    type: 'item',
    icon: 'import_contacts',
    url: '/rubrica'
  }, */
  {
    id: 'agenda',
    title: 'Agenda',
    translate: 'NAV.AGENDA',
    type: 'item',
    icon: 'calendar_today',
    url: '/agenda'
  },
  {
    id: 'notifiche',
    title: 'Notifiche',
    translate: 'NAV.NOTIFICHE',
    type: 'item',
    icon: 'check_box',
    url: '/todo',
    number: '0'
  },
  {
    id: 'contabilita',
    title: 'Contabilita',
    translate: 'NAV.CONTABILITA',
    icon: 'money',
    type: 'group',
    children: [
      {
        id: 'fattproposte',
        title: 'Fatture proposte',
        translate: 'NAV.FATTPROPOSTE',
        type: 'item',
        icon: 'pending_actions',
        url: '/contabilita/fatture/proposte'
      },
      {
        id: 'fattinviate',
        title: 'Fatture emesse',
        translate: 'NAV.FATTINVIATE',
        type: 'item',
        icon: 'assignment',
        url: '/contabilita/fatture/emesse'
      },
      {
        id: 'fattricevute',
        title: 'Fatture ricevute',
        translate: 'NAV.FATTRICEVUTE',
        type: 'item',
        icon: 'move_to_inbox',
        url: '/contabilita/fatture/ricevute'
      },
      {
        id: 'riconciliazione',
        title: 'Riconciliazione bancaria',
        translate: 'NAV.RICONCILIAZIONE',
        type: 'item',
        icon: 'euro_symbol',
        url: '/contabilita/riconciliazione'
      }
    ]
  },
  {
    id: 'menuspese',
    title: 'Spese',
    translate: 'NAV.SPESE',
    icon: 'group_work',
    type: 'group',
    children: [
      {
        id: 'inserimentoSpese',
        title: 'Registro Spese',
        translate: 'NAV.REGISTROSPESE',
        type: 'item',
        icon: 'folder',
        url: '/spese/registro'
      },
      {
        id: 'modelliSpese',
        title: 'Modelli spese',
        translate: 'NAV.MODELLISPESE',
        type: 'item',
        icon: 'group_work',
        url: '/spese/modelli'
      },
      /* {
        id: 'caricamentoSDI',
        title: 'Caricamento SDI',
        translate: 'NAV.CARICAMENTOSDI',
        type: 'item',
        icon: 'branding_watermark',
        url: '/spese/upload-sdi'
      }, */
      /*{
        id: 'marcheDaBollo',
        title: 'Marche da bollo',
        translate: 'NAV.MARCHEDABOLLO',
        type: 'item',
        icon: 'branding_watermark',
        url: '/spese/marche-da-bollo'
      },*/
      {
        id: 'speseDefault',
        title: 'Spese default',
        translate: 'NAV.SPESEDEFAULT',
        icon: 'group_work',
        type: 'group',
        children: [
          {
            id: 'modalitapagamento',
            title: 'Modalità pagamento',
            translate: 'NAV.MODALITAPAGAMENTO',
            type: 'item',
            icon: 'branding_watermark',
            url: '/spese/gestione/modalita-pagamento'
          },
          {
            id: 'vociSpesa',
            title: 'Voci spesa',
            translate: 'NAV.VOCISPESA',
            type: 'item',
            icon: 'branding_watermark',
            url: '/spese/gestione/voci-spesa'
          },
          {
            id: 'categorieSpese',
            title: 'Categorie spese',
            translate: 'NAV.CATEGORIESPESE',
            type: 'item',
            icon: 'branding_watermark',
            url: '/spese/gestione/categorie-spesa'
          },
          {
            id: 'aliquotaIva',
            title: 'Aliquota iva',
            translate: 'NAV.ALIQUOTAIVA',
            type: 'item',
            icon: 'branding_watermark',
            url: '/spese/gestione/aliquote-iva'
          }
        ]
      },
      {
        id: 'listino',
        title: 'listini',
        translate: 'NAV.LISTINI',
        icon: 'view_list',
        type: 'group',
        children: [
          {
            id: 'listinoMarchi',
            title: 'Listino marchi',
            translate: 'NAV.LISTINOMARCHI',
            type: 'item',
            icon: 'money',
            url: '/spese/listini/marchi',
          },
          {
            id: 'listinoBrevetti',
            title: 'Listino brevetti',
            translate: 'NAV.LISTINOBREVETTI',
            type: 'item',
            icon: 'money',
            url: '/spese/listini/brevetti',
          },
          /* {
            id: 'listinoCliente',
            title: 'Listino cliente',
            translate: 'NAV.LISTINOCLIENTE',
            type: 'item',
            icon: 'money',
            url: '/spese/listini/cliente',
          } */
        ]
      },

    ]
  },
];