export class Notifica {
  id: string;
  titolo: string;
  descrizione: string;
  utente: any;
  dataCreazione: any;
  stato: number;
  tags: any;
  completed: boolean;
  starred: boolean;
  important: boolean;
  progetto: any;
  progettoDest: any;
  fase: any;
  collegamento: string;
  mittente: any;
  note: string;

  /**
   * Constructor
   *
   * @param notifica
   */
  constructor(notifica?) {
    {
      notifica = notifica || {};
      this.id = notifica.id || '';
      this.titolo = notifica.titolo || '';
      this.descrizione = notifica.descrizione || '';
      this.utente = notifica.utente || '';
      this.dataCreazione = notifica.dataCreazione || new Date();
      this.stato = notifica.stato || '';
      this.tags = notifica.tags || [];
      this.completed = notifica.completed || false;
      this.starred = notifica.starred || false;
      this.important = notifica.important || false;
      this.progetto = notifica.progetto || '';
      this.fase = notifica.fase || '';
      this.collegamento = notifica.collegamento || '';
      this.mittente = notifica.mittente || '';
      this.note = notifica.note || '';
      this.progettoDest = notifica.progettoDest || '';
    }
  }

  /**
   * Toggle star
   */
  toggleStar(): void {
    this.starred = !this.starred;
  }

  /**
   * Toggle important
   */
  toggleImportant(): void {
    this.important = !this.important;
  }

  /**
   * Toggle completed
   */
  toggleCompleted(): void {
    this.stato = 2;
  }
}
