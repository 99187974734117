import { Injectable, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarDateFormatter, CalendarModule as AngularCalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MaterialModule } from 'app/core/material/material.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AgendaComponent } from './agenda.component';
import { AgendaService } from './agenda.service';
import { AgendaEventFormDialogComponent } from './event-form/event-form.component';

console.warn("Agenda module loaded");

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('ca', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('ca', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

}

const routes = [
  {
    path: '',
    component: AgendaComponent,
    resolve: { data: AgendaService }
  }
];

@NgModule({
  declarations: [
    AgendaComponent,
    AgendaEventFormDialogComponent
  ],
  imports: [
    RouterModule,
    MaterialModule,
    TranslateModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    }),
    ColorPickerModule,
    FuseSharedModule,
    FuseConfirmDialogModule
  ],
  exports: [
    AgendaComponent,
    AgendaEventFormDialogComponent
  ]
})
export class CalendarModule {
}
