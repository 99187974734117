import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'property' })
export class ObjectPropertyPipe implements PipeTransform {
  /**
   *
   */
  transform(input: any[], key: string): any[] {
    return input.map(value => value[key]);
  }
}
