<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">

  <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Selezione studio</mat-label>
      <mat-select [(ngModel)]="data.studio" required placeholder="Selezione studio" name="studio">
        <mat-option *ngFor="let studio of this.studi" [value]="studio">
          {{studio.denominazione}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="data.annulla" mat-raised-button color="warn" mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" [disabled]="!data.studio" [mat-dialog-close]="data.studio">Conferma</button>
</mat-dialog-actions>