import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class NazioniService {

  constructor(
    private db: AngularFirestore
  ) { }

  getNazioni() {
    return this.db.collection('nazioni', ref => ref.orderBy('descrizioneIT')).valueChanges({ idField: 'id' });
  }

  addNazione(nazione) {
    const id = nazione.id;
    delete nazione.id;

    return this.db.collection('nazioni').doc(id).set(nazione);
  }

  editNazione(nazione) {
    const id = nazione.id;
    delete nazione.id;

    return this.db.collection('nazioni').doc(id).update(nazione);
  }

  deleteNazione(nazione) {
    return this.db.collection('nazioni').doc(nazione.id).update({ stato: false });
  }
}

