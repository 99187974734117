// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {
        apiKey: "AIzaSyATZkBz6i5Z3JLr68zDyPzncnYB49HR4lk",
        authDomain: "sbarea-70fa2.firebaseapp.com",
        databaseURL: "https://sbarea-70fa2.firebaseio.com",
        projectId: "sbarea-70fa2",
        storageBucket: "sbarea-70fa2.appspot.com",
        messagingSenderId: "290618384172",
        appId: "1:290618384172:web:7c45d64f062373b5d5d689"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
