export class Studio {
  id: string;
  denominazione: string;
  partitaIva: string;
  codiceFiscale: string;
  sede: string;

  constructor(studio?) {
    studio = studio || {};
    this.id = studio.id || '';
    this.denominazione = studio.denominazione || '';
    this.partitaIva = studio.partitaIva || '';
    this.codiceFiscale = studio.codiceFiscale || '';
    this.sede = studio.sede || '';
  }
}
