export const locale = {
    lang: 'en',
    data: {
        'progetto': 'Project',
        'fase': 'Phase',
        'data': 'Date',
        'startHour': 'Start hour',
        'startMinutes': 'Start minutes',
        'endHour': 'End hour',
        'endMinutes': 'End minutes',
        'note': 'Notes'
    }
};
