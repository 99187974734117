import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class InformazioniService {

  constructor(private db: AngularFirestore) { }

  getTipologie() {
    return this.db.collection('informazioni').doc("TIPOLOGIE").valueChanges();
  }

  getTipologiePromise() {
    return this.db.firestore.collection('informazioni').doc("TIPOLOGIE").get();
  }

  getSottotipologie() {
    return this.db.collection('informazioni').doc("SOTTOTIPOLOGIE").valueChanges();
  }

  getSottotipologiePromise() {
    return this.db.firestore.collection('informazioni').doc("SOTTOTIPOLOGIE").get();
  }

  getOrganiGiudicanti() {
    return this.db.collection('informazioni').doc("ORGANI-GIUDICANTI").valueChanges();
  }

  getSettori() {
    return this.db.collection('informazioni').doc("SETTORI").valueChanges();
  }

  getBasiListinoMarchio() {
    return this.db.collection('informazioni').doc("BASI-LISTINO-MARCHI").valueChanges();
  }

  updateBasiListinoMarchio(basi) {
    return this.db.collection('informazioni').doc("BASI-LISTINO-MARCHI").update(basi);
  }

  getStatiFase() {
    return this.db.collection('informazioni').doc("STATI-FASE").valueChanges();
  }

  getStatiFattura() {
    return this.db.collection('informazioni').doc("STATI").valueChanges();
  }
}